import { Component, EventEmitter, inject, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import moment from 'moment';
import { BehaviorSubject } from 'rxjs';
import { Scenario } from '../../model/scenario.model';
import { TranslateService } from "@ngx-translate/core";

@Component({
  selector: 'app-scenario-progress-v2',
  templateUrl: './scenario-progress-v2.component.html'
})
export class ScenarioProgressV2Component implements OnChanges {
  @Input() isProcessing: boolean = false;
  @Input() disabled: boolean = false;
  @Input() scenario: Scenario;
  @Input() isInternalUser: boolean = false;

  @Output() onClick = new EventEmitter<void>();

  private progress$ = new BehaviorSubject<number>(0);
  public progressing$ = this.progress$.asObservable();
  public translateService = inject(TranslateService);

  constructor() {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.scenario) {
      this.scenarioJobsProgress(this.scenario);
    }
  }

  ngOnDestroy(): void {
    this.progress$.complete();
  }

  private scenarioJobsProgress(scenario: Scenario) {
    const { currentScenarioJob, estimatedRuntime } = scenario;
    const { createdAt } = currentScenarioJob || {};
    const duration = !!estimatedRuntime && this.isProcessing ? (moment().diff(createdAt, 'seconds') / estimatedRuntime) : 0;
    this.progress$.next(duration);
  }

  handleClick(): void {
    this.onClick.emit();
  }

  get isError(): boolean {
    if (this.isProcessing) return false
    const state = this.scenario.currentScenarioJob?.state || this.scenario.currentScenarioJobState;
    return (
      state === 'FAILED' || state === 'ERROR' || state === 'CANCELLED'
    );
  }

  formatInternalError(errors: any | undefined) {
    const isTrainingError = errors?.errorMessages?.some((error) => /Client Error|ONNXRuntimeError/.test(error));
    if (!isTrainingError) {
      return errors?.errorMessages?.join("\n");
    } else {
      const [code, , , , , pillar, model] = errors?.errorMessages[0]?.split("/");
      const notFound = /NO_SUCHFILE|404/.test(code);
      return this.translateService.instant(`error.nn.${ notFound ? 'notFound' : 'error' }`, { pillar: pillar, model });
    }
  }
}
