import { gql } from 'apollo-angular';
import { SCENARIO_JOB_FRAGMENT, SCENARIO_FRAGMENT } from './queries';

export const ARCHIVE_SCENARIO = gql`
  ${SCENARIO_JOB_FRAGMENT}
  ${SCENARIO_FRAGMENT}
  mutation archive($scenarioId: BigInt) {
    archiveScenario(scenarioId: $scenarioId) {
      scenario {
        ...ScenarioBase
        schedule {
          hour
          days
        }
        currentScenarioJob {
          ...ScenarioJob
        }
      }
      __typename
    }
  }
`

export const RESTORE_SCENARIO = gql`
  ${SCENARIO_JOB_FRAGMENT}
  ${SCENARIO_FRAGMENT}
  mutation restore($scenarioId: BigInt) {
    restoreScenario(scenarioId: $scenarioId) {
      scenario {
        ...ScenarioBase
        schedule {
          hour
          days
        }
        currentScenarioJob {
          ...ScenarioJob
        }
        __typename
      }
      __typename
    }
  }`

export const RESET_SCENARIO_TYPE = gql`
  mutation clearScenarioType($id: BigInt, $budget: Boolean) {
    clearScenarioType(id: $id, budget: $budget) {
      scenario {
        id
        type
        budget
        default
      }
    }
  }`

export const CANCEL_SCENARIO = gql`
  mutation CancelScenario($scenarioId: BigInt) {
    cancelScenario(id: $scenarioId) {
      scenario {
        id
        name
        __typename
      }
      __typename
    }
  }
`

export const DELETE_SNAPSHOTS = gql`
  mutation DeleteSnapshots($scenarioId: BigInt) {
    deleteScenarioSnapshots(id: $scenarioId) {
      success
    }
  }
`

export const FAVOURITE_SCENARIO = gql`
  mutation FavouriteScenario($scenarioId: BigInt!, $favourite: Boolean!) {
    favouriteScenario(scenarioId: $scenarioId, favourite: $favourite) {
      favourite
    }
  }
`

export const CREATE_SCENARIO = gql`
  ${SCENARIO_FRAGMENT}
  mutation CreateScenario($name: String!, $productId: Int, $internal: Boolean, $fromScenarioId: BigInt, $description: String) {
    createScenario(name: $name, productId: $productId, internal: $internal, fromScenarioId: $fromScenarioId, description: $description) {
      scenario {
        ...ScenarioBase
        __typename
      }
      __typename
    }
  }
`
