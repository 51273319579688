import {Inject, Injectable, PLATFORM_ID, signal} from '@angular/core';
import {isPlatformBrowser} from '@angular/common';
import { AnalyticsBrowser, EventProperties, Traits } from '@segment/analytics-next';
import { User } from '@auth0/auth0-angular';
import { AnalyticsNextConfig } from '../config/models/config';

export interface PageTrackingData {
  category?: string;
  name: string;
  properties?: EventProperties;
}
@Injectable({providedIn: 'root'})
export class AnalyticsNextService {

  private analytics: AnalyticsBrowser;
  private analyticsEnabled: boolean;

  private user = signal<User | null>(null);

  constructor(
    @Inject(PLATFORM_ID) protected platformId: object,
  ){
    if (!isPlatformBrowser(this.platformId)) {
      return;
    }
    this.analytics = new AnalyticsBrowser();
  }

  public async init(analyticsNextConfig: AnalyticsNextConfig): Promise<void> {
    if (!isPlatformBrowser(this.platformId)) return;
    const { writeKey } = analyticsNextConfig;
    try {
      await this.analytics.load({ writeKey });
      await this.analytics.identify(this.user().userId, this.user().traits);
    } catch (error) {
      console.warn('Segment analytics-next failed to load', error);
    }
  }

  public setUser(userId: string, traits: Traits): void {
    this.user.set({ userId, traits });
  }

  public track(event: string, data = {}): void {
    if(!this.analyticsEnabled) false;
    this.analytics.track(event, {
      ...data,
      ...this.sharedEventProperties(),
    });
  }

  public page(data: PageTrackingData): void {
    if(!this.analyticsEnabled) false;
    /*
      TODO: Temporarily disabled page tracking due to the issue with billing: https://app.clickup.com/t/8697ucmfg
    */

    // this.analytics.page(data.name, {
    //   ...data,
    //   ...this.sharedEventProperties(),
    // });
  }

  private sharedEventProperties(): EventProperties {
    return {
      ui_type: 'new',
      user: {
        email: this.user().traits?.email,
        company: this.user().traits?.company?.name,
      }
    };
  }

  public shutdown(): void {
    if(!this.analyticsEnabled) false;
    // Clears cookies and localStorage created by Segment.com
    this.analytics.reset();
  }

}

