<app-dropdown-menu [hasDropDown]="type !== ScenarioType.DEFAULT" menuPosition="bottom-left">
  <app-badge buttonContent [variant]="getVariant(this.type)" class="cursor-pointer">
    <ng-content/>
    @if (type && type !== ScenarioType.DEFAULT) {
      <button type="button" (click)="clearType($event)"
        class=" p-0.5 ml-1 active hover:bg-soft-blue dark:hover:bg-slate-800 rounded">
        <app-svg-images class="h-3 w-3 block" svgName="close"/>
      </button>
    }
  </app-badge>
  <div menuItems class="text-sm -m-1.5">
    <div class="flex flex-col">
      @for (item of types; track item) {
        <div class="group/scenarioFilter flex items-center gap-3">
          <button
            (click)="setType(item)"
            class="text-left inline-flex items-center gap-2.5 py-1.5 px-2 rounded-md active text-sm font-medium -mr-1.5 hover:bg-soft-blue dark:hover:bg-slate-800 dark:group-hover/scenarioFilter:bg-slate-800 text-black/60 dark:text-white/60 hover:text-black dark:hover:text-white">
            <div class="flex items-center gap-3">
              <app-badge class="mx-1" [variant]="getVariant(item)">
                {{ 'data.scenario.types.' + item | translate }}
              </app-badge>
            </div>
          </button>
        </div>
      }
    </div>
  </div>
</app-dropdown-menu>
