import { gql } from 'apollo-angular';

export const scenarioMarketingOverrides = `
  marketingFatigueOverrides {
      productBlendedSegmentIds
      value
  }
  paidPercentageOverrides {
      productBlendedSegmentIds
      value
  }
  cpiSensitivityToSpendOverrides {
      productBlendedSegmentIds
      value
  }
`;

export const scenarioOrganicsSegment = `
    id
    scenarioId
    autoOrganicFactor
    autoTrueOrganics
    manualOrganicFactor
    manualTrueOrganics
    manualUpdatedAt
    updatedAt
    updatedBy
    warningType      
    productBlendedSegmentId
    productBlendedSegment {
      id
      name
    }
`;

export const scenarioMarketingFields = `
      paybackMethod
      mappedPaybackMethod
      spendLookbackNumberOfDays
      spendLookbackRelative
      spendLookbackDate
      calibrateOrganicsLastRunTime
      organicsLookbackNumberOfDays
      organicsLookbackRelative
      organicsLookbackDate
      defaultPaidPercentage
      id
      mode
      scenarioId
      targetPayback
      defaultPaidPercentage
      defaultCpiSensitivityToSpend
      defaultMarketingFatigue
      overrideProductPaidPercentage
      roasTargetPercentage
      buyClv
      blendedSegmentsList {
        edges {
          node {
            segmentId
            paidPercentage
            segment {
              name
            }
          }
        }
      }
`;

export const SCENARIO_ORGANICS_SEGMENTS_QUERY = gql`
  query scenarioOrganicsSegments($scenarioId: BigInt!) {
    scenarioOrganicsSegments(scenarioId: $scenarioId) {
      edges {
        node {
          ${scenarioOrganicsSegment}
        }
      }
    }
  }
`;

export const SCENARIO_MARKETING_QUERY = gql`
  query scenarioMarketingConfig($scenarioId: BigInt!) {
    scenarioMarketingConfig(scenarioId: $scenarioId) {
      ${scenarioMarketingFields}
      segmentCount
      blendedSegments{
        name
        id
      }
      ${scenarioMarketingOverrides}
      overrideSegmentsForPaid {
        id
        name
      }
      overrideSegmentsForPvc {
        id
        name
      }
    }
  }
`;

export const SCENARIO_MARKETING_CALIBRATION_MUTATION = gql`
  mutation CalibrateOrganics($method: OrganicsCalibrationMethod!, $scenarioId: BigInt!) {
    calibrateOrganics(method: $method, scenarioId: $scenarioId) {
      organicsSegments {
        ${scenarioOrganicsSegment}
      }
    }
  }
`;

export const SCENARIO_MARKETING_UPDATE_ORGANICS_MUTATION = gql`
  mutation UpdateScenarioOrganicsSegment($id: UUID!, $manualOrganicFactor: Float, $manualTrueOrganics: Float) {
    updateScenarioOrganicsSegment(id: $id, manualOrganicFactor: $manualOrganicFactor, manualTrueOrganics: $manualTrueOrganics) {
      ${scenarioOrganicsSegment}
    }
  }
`;

export const SCENARIO_MARKETING_MUTATION = `
  mutation updateMarketingConfig($scenarioId: Int, $scenarioMarketingConfig: ScenarioMarketingConfigInput!) {
    updateMarketingConfig(scenarioId: $scenarioId, scenarioMarketingConfig: $scenarioMarketingConfig) {
      scenarioMarketingConfig {
        ${scenarioMarketingFields}
        overrideSegmentsForPaid {
            id
            name
        }
        overrideSegmentsForPvc {
            id
            name
        }
        scenario {
            config
        }
      }
    }
  }
`;

export const SCENARIO_MARKETING_PVC_PREVIEW_QUERY = `
    query scenarioMarketingPvcPreview($scenarioId: BigInt!, $segmentIds: [String], $cpiSensitivityToSpend: Float!, $marketingFatigue: Float!) {
        scenarioMarketingPvcPreview(scenarioId: $scenarioId, productBlendedSegmentIds: $segmentIds, cpiSensitivityToSpend: $cpiSensitivityToSpend, marketingFatigue: $marketingFatigue) {
            dnuBaseline
            dnuFatigue
            cpiBaseline
            cpiFatigue
            cpiActualLastYear
            cpiActualLookback
            dnuActualLastYear
            dnuActualLookback
            actualsAvg
            pvModel
        }
    }
`;

export const SCENARIO_MARKETING_ORGANICS_PREVIEW_QUERY = gql`
  query organicsPreview($scenarioId: BigInt!) {
    organicsPreview(scenarioId: $scenarioId) {
      allTimeDataPoints {
        x
        y
      }
      lookbackDataPoints {
        x
        y
      }
      fitLinePoints{
        x
        y
      }
    }
  }
`;

export const SCENARIO_MARKETING_ORGANICS_SEGMENT_PREVIEW_QUERY = gql`
  query organicsSegmentPreview($id: UUID!, $manualOrganicFactorOverride: Float, $manualTrueOrganicsOverride: Float) {
    organicsSegmentPreview(id: $id, manualOrganicFactorOverride: $manualOrganicFactorOverride, manualTrueOrganicsOverride: $manualTrueOrganicsOverride) {
      allTimeDataPoints {
        x
        y
      }
      lookbackDataPoints {
        x
        y
      }
      autoFitLinePoints {
        x
        y
      }
      manualFitLinePoints {
        x
        y
      }
    }
  }
`;

export const SCENARIO_MARKETING_OPTIMIZE_CPI_SENSITIVITY_MUTATION = gql`
  mutation optimizeCpiSensitivity($scenarioId: Int) {
    optimizeCpiSensitivity(scenarioId: $scenarioId) {
      scenarioMarketingConfig {
        cpiSensitivityToSpendOverrides {
          productBlendedSegmentIds
          value
        }
        defaultCpiSensitivityToSpend
      }
    }
  }
`;