import { Injectable, signal } from '@angular/core';
import { HttpClient, HttpResponse, HttpErrorResponse } from '@angular/common/http';
import { Observable, lastValueFrom, throwError } from 'rxjs';
import { IPillarModel, IScenario, pillarModelsType, pillarType, Scenario } from '../model/scenario.model';
import { AppState } from '../../../store';
import { Store } from '@ngrx/store';
import * as fromScenario from '../../../store/actions/scenario.actions';
import * as fromAnalytics from '../../../store/actions/analytics.actions';
import { catchError, filter, map, tap } from 'rxjs/operators';
import { ScenarioUpdateDtoModel } from '../model/scenario-update-dto.model';
import { ScenarioOverride } from '../model/scenario-override.models';
import { IScenarioJobDto } from '../model/scenario-job-dto.model';
import { GraphqlService } from '../../../graphql/graphql.service';
import {
  ALL_PILLAR_MODEL_QUERY,
  PILLAR_MODEL_QUERY,
  SCENARIO_INSIGHTS_QUERY,
  SCENARIO_JOB_LIST_QUERY,
  SCENARIO_JOB_QUERY,
  SCENARIO_LIST_QUERY,
  SCENARIO_QUERY
} from '../graphql/queries';
import { GraphQLEdgesResponse, GraphQLParams, GraphQLResponse, IGraphQLParams } from '../../../graphql/graphql.models';
import { captureException } from '@sentry/angular';
import { ValidationException } from '../../../core/exceptions/ValidationException';
import {
  ARCHIVE_SCENARIO,
  CANCEL_SCENARIO,
  DELETE_SNAPSHOTS,
  FAVOURITE_SCENARIO,
  RESET_SCENARIO_TYPE,
  RESTORE_SCENARIO
} from '../graphql/mutations';
import { deleteScenarioJobsActivity } from 'src/app/core/activity/store';
import { CREATE_SCENARIO } from '../graphql/mutations';
import { AlertService } from 'src/app/core/services/alert.service';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';

@Injectable({ providedIn: 'root' })
export class ScenarioService extends GraphqlService {

  productId = signal(null);

  get runwayUrl() {
    return this.environment.resourceServer.runwayUrl;
  }

  get resourceUrl() {
    return this.runwayUrl + 'scenarios';
  }

  constructor(
    private http: HttpClient,
    private alertService: AlertService,
    private router: Router,
    private translateService: TranslateService,
    private store: Store<AppState>
  ) {
    super();
  }

  update(scenario: ScenarioUpdateDtoModel, trackingData = null): Promise<IScenario> {
    return lastValueFrom(this.http.put<IScenario>(this.resourceUrl + `/${ scenario.id }`, scenario).pipe(
      tap(scenario => {
        if (trackingData) {
          this.store.dispatch(fromAnalytics.trackItem(trackingData));
        }
        ;
      }),
    ));
  }

  updateScenario(scenario: ScenarioUpdateDtoModel): Observable<HttpResponse<IScenario>> {
    return this.http.put<IScenario>(this.resourceUrl + `/${ scenario.id }`, scenario, {
      observe: 'response'
    });
  }

  updateConfig(scenario: IScenario, trackingData: {
    pillar: string;
    data: any
  } = null): Observable<HttpResponse<IScenario>> {
    return this.http.put<IScenario>(this.resourceUrl + `/${ scenario.id }/config`, { config: scenario.config }, {
      observe: 'response'
    }).pipe(
      tap(_scenario => {
        if (Boolean(trackingData)) {
          trackingData.data.forEach((data) => {
            const { name, current, previous } = data;
            this.store.dispatch(fromAnalytics.trackItem({
              label: `Scenario ${ trackingData.pillar } Setting Updated`,
              data: {
                product_id: scenario.product.id,
                product_name: scenario.product.name,
                scenario_id: scenario.id,
                scenario_name: scenario.name,
                scenario_setting: name,
                scenario_setting_old: previous,
                scenario_setting_new: current,
              }
            }))
          });
        }
        this.store.dispatch(fromScenario.updateScenarioSuccess({
          scenario: {
            id: _scenario.body.id,
            changes: _scenario.body
          }
        }))
      }),
      catchError((error: HttpErrorResponse) => {
        captureException(new ValidationException(error.error.message, error), {
          extra: error.error.errors?.config
        });
        return throwError(error);
      })
    );
  }

  find(scenarioId: number): Observable<IScenario> {
    return this.apollo.query<GraphQLResponse<any>>({
      query: SCENARIO_QUERY,
      fetchPolicy: 'no-cache',
      variables: {
        scenarioId,
        pageSize: 1,
        sort: ['UPDATED_AT_DESC'],
      }
    }).pipe(
      map(response => {
        if (response.errors) {
          const errorMessages: string = [...new Set(response.errors.map(err => err.message))].join('.');
          console.error('[Scenario Query Errors]: ', errorMessages);
        }
        if (!response.data.scenario) {
          console.error(`Scenario ID "${ scenarioId }" not found`);
        }
        if (response.errors || !response.data.scenario) {
          this.alertService.error(this.translateService.instant('data.scenario.loadError', { id: scenarioId }));
          if (this.productId()) {
            this.router.navigate(['/products', this.productId(), 'scenarios']);
          } else {
            this.router.navigate(['/products']);
          }
          return null;
        }

        return {
          ...response.data.scenario,
          scenarioUserEvent: response.data.scenario?.scenarioUserEvent.edges?.map(edge => edge.node)[0]
        }
      }),
      filter(scenario => !!scenario)
    );
  }

  delete(scenario: IScenario): Observable<null> {
    return this.http.delete<null>(this.resourceUrl + `/${ scenario.id }`).pipe(
      map((data) => {
        const { id, name, product } = scenario;
        this.store.dispatch(fromAnalytics.trackItem({
          label: 'Scenario Deleted',
          data: {
            product_id: product.id,
            product_name: product.name,
            scenario_id: id,
            scenario_name: name
          }
        }));
        this.store.dispatch(deleteScenarioJobsActivity({ scenarioId: scenario.id }));
        return data;
      })
    );
  }

  query<T>(options: IGraphQLParams = new GraphQLParams(), query = SCENARIO_LIST_QUERY): Observable<GraphQLEdgesResponse<T>> {
    const { page, pageSize, sort, filter } = options;
    return this.apollo.query<any>({
      query,
      fetchPolicy: 'network-only',
      variables: {
        page,
        pageSize,
        ...(sort && sort.length > 0) && { sort: ['SCENARIO_TYPE_ASC', 'ARCHIVED_ASC', sort.join('_').toUpperCase()] },
        ...(filter) && { filter }
      },
    }).pipe(
      map((scenarios) => ({
        loading: scenarios.loading,
        totalCount: scenarios.data.scenarios.totalCount,
        results: scenarios.data.scenarios.edges.map(scenario => ({
          ...scenario.node,
          scenarioUserEvent: scenario.node.scenarioUserEvent?.edges?.map(edge => edge.node)[0]
        }))
      })),
    );
  }

  getJobs(graphQLParams: IGraphQLParams, query = SCENARIO_JOB_LIST_QUERY): Observable<GraphQLEdgesResponse<IScenarioJobDto>> {
    return this.apollo.query<any>({
      query,
      fetchPolicy: 'network-only',
      variables: this.mapParams(graphQLParams)
    }).pipe(
      map((response) => ({
          loading: response.loading,
          totalCount: response.data.scenarioJobs.totalCount,
          results: response.data.scenarioJobs.edges.map(scenarioJob => scenarioJob.node)
        })
      ));
  }

  getJob(id: number): Observable<IScenarioJobDto> {
    return this.apollo.query<any>({
      query: SCENARIO_JOB_QUERY,
      fetchPolicy: 'network-only',
      variables: {
        id
      }
    }).pipe(
      map((response) => response.data.scenarioJob)
    );
  }

  getLatestJob(scenarioId: number): Observable<IScenarioJobDto> {
    return this.apollo.query<any>({
      query: SCENARIO_JOB_LIST_QUERY,
      fetchPolicy: 'network-only',
      variables: {
        filter: { scenarioId },
        sort: ['ID_DESC'],
        pageSize: 1,
      }
    }).pipe(
      map((response) => response.data.scenarioJobs.edges.map(scenarioJob => scenarioJob.node)[0])
    )
  }

  downloadConfig(scenario: Scenario): void {
    const downloadAnchorNode = document.createElement('a');
    const dataStr = 'data:text/json;charset=utf-8,' + encodeURIComponent(JSON.stringify(scenario.config, null, 2));
    downloadAnchorNode.setAttribute('href', dataStr);
    downloadAnchorNode.setAttribute('download', scenario.name.toLowerCase() + '-config.json');
    downloadAnchorNode.click();
  }

  fail(scenario: Scenario) {
    return this.http.put<Scenario>(this.resourceUrl + `/${ scenario.id }`, {
      state: 'FAILED'
    });
  }

  create(name: string, productId: number, internal: boolean, fromScenario: IScenario | null, description?: string): Observable<any> {
    return this.apollo.mutate<any>({
      mutation: CREATE_SCENARIO,
      variables: {
        name,
        productId,
        internal,
        fromScenarioId: fromScenario?.id,
        description: description ?? fromScenario?.description,
      }
    }).pipe(
      map((response) => {
        const scenario = response.data.createScenario.scenario;
        const { id, name, product } = scenario;
        const label = fromScenario ? 'Scenario Duplicated' : 'Scenario Created';
        const data = {
          product_id: product.id,
          product_name: product.name,
        };
        if (fromScenario) {
          data['scenario_cloned_id'] = id;
          data['scenario_cloned_name'] = name;
          data['scenario_original_id'] = fromScenario.id;
          data['scenario_original_name'] = fromScenario.name;
        } else {
          data['scenario_id'] = id;
          data['scenario_name'] = name;
        }

        this.store.dispatch(fromAnalytics.trackItem({
          label,
          data,
        }));

        return scenario;
      })
    )
  }

  /**
   * Get overrides for segments
   */
  overrides(scenarioId: number): Observable<ScenarioOverride[]> {
    return this.http.get<ScenarioOverride[]>(`${ this.resourceUrl }/${ scenarioId }/overrides`);
  }

  getPillarModel(pillar: pillarType): Observable<IPillarModel[]> {
    return this.apollo.query<any>({
        fetchPolicy: 'network-only',
        query: PILLAR_MODEL_QUERY,
        variables: {
          pillar
        }
      }
    ).pipe(
      map(response => response.data.pillarModels)
    );
  }

  getPillarModels(): Observable<pillarModelsType> {
    return this.apollo.query<any>({
        fetchPolicy: 'network-only',
        query: ALL_PILLAR_MODEL_QUERY,
        variables: {
          retention: 'retention',
          monetisation: 'monetisation',
          newUsers: 'new_users'
        }
      }
    ).pipe(
      map(response => response.data),
    );
  }

  archive(scenario: Scenario): Observable<IScenario> {
    const operation = scenario.archived ? 'restoreScenario' : 'archiveScenario';
    return this.apollo.mutate<any>({
      mutation: (scenario.archived) ? RESTORE_SCENARIO : ARCHIVE_SCENARIO,
      variables: {
        scenarioId: scenario.id
      }
    }).pipe(
      map(response => response.data[operation].scenario),
    )
  }

  cancelScenario(scenarioId: number): Observable<IScenario> {
    return this.apollo.mutate<any>({
      mutation: CANCEL_SCENARIO,
      variables: {
        scenarioId
      }
    }).pipe(
      map(response => response.data.cancelScenario.scenario),
    )
  }

  deleteSnapshots(scenarioId: number): Observable<boolean> {
    return this.apollo.mutate<any>({
      mutation: DELETE_SNAPSHOTS,
      variables: {
        scenarioId
      }
    }).pipe(
      map(response => response.data.deleteScenarioSnapshots.success),
    )
  }

  setScenarioFavourite(scenarioId: number, favourite: boolean): Observable<any> {
    return this.apollo.mutate<any>({
      mutation: FAVOURITE_SCENARIO,
      variables: {
        scenarioId,
        favourite
      }
    }).pipe(
      map(response => response.data.favouriteScenario),
    )
  }

  getScenarioInsights(scenarioId: number, jobId: number): Observable<any> {
    return this.apollo.query<any>({
      query: SCENARIO_INSIGHTS_QUERY,
      fetchPolicy: 'cache-first',
      variables: {
        scenarioId,
        jobId
      }
    }).pipe(
      map(response => response.data.scenario.insights),
    )
  }

  setProductId(productId: number): void {
    this.productId.set(productId);
  }

  removeScenarioType(scenario: ScenarioUpdateDtoModel) {
    return this.apollo.mutate<any>({
      mutation: RESET_SCENARIO_TYPE,
      variables: {
        id: scenario.id,
        budget: scenario.budget
      }
    }).pipe(
      map(response => response.data.clearScenarioType.scenario),
    )
  }
}
