@let isNew = (scenario.currentScenarioJob?.state || scenario.currentScenarioJobState) === 'NEW';
@let hasErrors = (!!scenario.currentScenarioJob?.errors?.userMessage || !!scenario.currentScenarioJob?.errors?.errors);
@let tooltipDisabled = !scenario.currentScenarioJob?.createdAt;

<div [appTooltip]="tooltipContent" [tooltipDisabled]="tooltipDisabled" tooltipPosition="left"
    class="flex items-center gap-5 py-2.5 pl-4 pr-3.5 rounded-full overflow-hidden border group-hover/scenarioRow:border-gray-300 group-hover/scenarioRow:bg-white relative group-hover/scenarioRow:shadow-sm"
    [ngClass]="{
        'border-gray-200': isProcessing,
        'border-transparent': !isProcessing,
        'opacity-50' : scenario.locked
    }">
    <div class="left-0 top-0 bottom-0 absolute border-r bg-gradient-to-bl from-digital-blue/20 to-digital-blue/70 opacity-30 transition-all"
        [ngClass]="{
            'animate-pulse': isProcessing
        }"
        [ngStyle]="{'width': isProcessing ? 'calc(' + (progressing$ | async) + ' * 100%)' : 0}"></div>
    <div class="h-full flex items-center w-[150px]">
        <div class="text-[13px]">
            <div class="flex items-center gap-3">
                <div class="flex gap-2.5 items-center capitalize text-[13px]">
                    <span class="relative flex h-2 w-2">
                        @if (isProcessing) {
                            <span class="animate-ping absolute inline-flex h-full w-full rounded-full bg-digital-blue opacity-75"></span>
                        }
                        <span class="relative inline-flex rounded-full h-2 w-2" [ngClass]="{
                            'bg-digital-blue': isProcessing,
                            'bg-gray-400': isNew,
                            'bg-teal-green': !isProcessing && !isError && !isNew,
                            'bg-red-500': isError
                        }">
                        </span>
                    </span>
                    <span>{{ (scenario.currentScenarioJob?.state || scenario.currentScenarioJobState) | titlecase }}</span>
                </div>
            </div>
            @if (scenario.currentScenarioJob?.createdAt) {
                <div class="flex items-center gap-1">
                    <div class="text-xs text-gray-500 pl-5 leading-none">
                        {{ scenario.currentScenarioJob?.createdAt | dateTimeAgo }}
                    </div>
                    @if (isProcessing) {
                        <div class="text-xs text-digital-blue font-medium leading-none">
                            <app-duration-counter class="inline-block" [state]="scenario.currentScenarioJob?.state"
                            [start]="scenario.currentScenarioJob?.createdAt" [end]="scenario.currentScenarioJob?.transformingEnd">
                            </app-duration-counter>
                        </div>
                    }
                </div>
            }
        </div>
    </div>
    <button data-cy="play-stop-button"
        class="w-8 h-8 rounded-full border group-hover/scenarioRow:border-gray-200 group-hover/scenarioRow:shadow-sm inline-flex items-center justify-center active bg-white group-hover/scenarioRow:text-black"
        [ngClass]="{
            'border-gray-300': isProcessing || isError || scenario.stale,
            'border-transparent text-gray-400': !isProcessing && !isError && !scenario.stale
        }" [disabled]="disabled" (click)="handleClick()">
        <app-svg-images class="h-3.5 block group-hover/scenarioProgress:text-black"
            [ngClass]="{ 'text-kohort-primary': scenario.stale && !isProcessing, 'text-gray-500': !scenario.stale && !isProcessing }"
            [svgName]="isProcessing ? 'stop' : 'play'">
        </app-svg-images>
    </button>
</div>

<ng-template #tooltipContent>
    <div class="max-w-[30vw] bg-white outline-none border box-border text-xs font-normal rounded-md shadow-md">
        <div class="px-4 pt-2 pb-3 space-y-1">
            <div class="flex items-center gap-2">
                <h4 class="flex items-center gap-2 text-gray-500">
                    <app-svg-images class="h-3 inline-flex" svgName="calendar-days"></app-svg-images>
                    <span>{{ 'global.lastRun' | translate }}</span>
                </h4>
                <p class="inline-block">{{ scenario.currentScenarioJob?.createdAt | date: 'd MMMM y HH:mm' }}</p>
            </div>
            <div class="flex items-center gap-2">
                <h4 class="flex items-center gap-2 text-gray-500">
                    <app-svg-images class="h-3 inline-flex" svgName="clock"></app-svg-images>
                    <span>{{ 'global.runtime' | translate }}</span>
                </h4>
                <app-duration-counter class="inline-block" [state]="scenario.currentScenarioJob?.state"
                    [start]="scenario.currentScenarioJob?.createdAt" [end]="scenario.currentScenarioJob?.transformingEnd">
                </app-duration-counter>
            </div>

            @if(hasErrors) {
                <div class="text-red-800 border-red-300 bg-red-50 p-3 mt-2 border rounded-md">
                    @if(isInternalUser) {
                        <span>{{ formatInternalError(scenario.currentScenarioJob?.errors) | translate }}</span>   
                    } @else {
                        <span>{{ scenario.currentScenarioJob?.errors?.userMessage }}</span>
                    }
                </div>
            }
        </div>
    </div>
</ng-template>