import { createAction, props } from '@ngrx/store';
import { IScenarioJobDto } from 'src/app/views/scenario/model/scenario-job-dto.model';
import { IScenarioOverrideEvent } from 'src/app/views/scenario/model/scenario-override.models';
import { ScenarioUpdateDtoModel } from 'src/app/views/scenario/model/scenario-update-dto.model';
import {
  IPillarModel,
  IScenarioUserEventPayload,
  pillarModelsType,
  pillarType,
  Scenario,
} from '../../views/scenario/model/scenario.model';
import { Update } from '@ngrx/entity';
import { IGraphQLParams } from 'src/app/graphql/graphql.models';

export const loadScenarioFiles = createAction(
  '[Scenario] Load Scenario Files',
  props<{ scenarioId: number }>()
);

export const setScenario = createAction(
  '[Scenario] Set Scenario',
  props<{ scenario: Scenario }>()
);

export const scenarioProcessed = createAction(
  '[Scenario] Scenario Processed',
  props<{ scenarioId: number; productId: number; name: string, productName?: string }>()
);

export const scenarioSnapshotDataDeleted = createAction(
  '[Scenario] Scenario Snapshot Data Deleted',
  props<{ scenarioId: number }>()
);

export const updateScenario = createAction(
  '[Scenario] Update Scenario',
  props<{ scenarioUpdate: ScenarioUpdateDtoModel; next?: () => void }>()
);

export const resetScenarioType = createAction(
  '[Scenario] Reset Scenario Type',
  props<{ scenarioUpdate: ScenarioUpdateDtoModel }>()
);

export const scenarioUpdated = createAction(
  '[Scenario] Scenario Updated',
  props<{ scenarioId: number }>()
);

export const loadScenariosFailure = createAction(
  '[Scenario] Load Scenarios Failure',
  props<{ error: any }>()
);

export const loadScenarioJobsSuccess = createAction(
  '[Scenario] Load Scenario Jobs Success',
  props<{ scenarioJobs: IScenarioJobDto[] }>()
);

export const loadScenarioJobsFailure = createAction(
  '[Scenario] Load Scenarios Jobs Failure',
  props<{ error: any }>()
);

export const loadScenarioLatestJob = createAction(
  '[Scenario] Load Scenario Latest Job',
  props<{ scenarioId: number }>()
);

export const setScenarioJob = createAction(
  '[Scenario] Set Scenario Job For Scenario',
  props<{ scenarioJob: IScenarioJobDto }>()
);

export const loadScenarioLatestJobSuccess = createAction(
  '[Scenario] Load Scenario Latest Job Success',
  props<{ scenarioLatestJob: IScenarioJobDto }>()
);

export const loadScenarioLatestJobFailure = createAction(
  '[Scenario] Load Scenarios Latest Job Failure',
  props<{ error: any }>()
);

export const setOverrideEvent = createAction(
  '[Scenario] Set override event For Scenario',
  props<{ overrideEvent?: IScenarioOverrideEvent }>()
);

export const setPillarModel = createAction(
  '[Scenario] Set a Pillar Model',
  props<{ pillar: pillarType }>()
);

export const setPillarModelSuccess = createAction(
  '[Scenario] Set a Pillar Model Success',
  props<{ pillar: pillarType; pillarModels: IPillarModel[] }>()
);

export const setPillarModelFailure = createAction(
  '[Scenario] Set a Pillar Model Failure',
  props<{ error: any }>()
);

export const setPillarModels = createAction(
  '[Scenario] Set all Pillar Models'
);

export const setPillarModelsSuccess = createAction(
  '[Scenario] Set all Pillar Models Success',
  props<{ pillarModels: pillarModelsType }>()
);

export const setPillarModelsFailure = createAction(
  '[Scenario] Set all Pillar Models Failure',
  props<{ error: any }>()
);

// V2 - Scenario List with entities implimentation

export const fetchScenarios = createAction(
  '[Scenario/API] Fetch scenarios',
  props<{ paginationParams: IGraphQLParams }>()
);

export const addScenario = createAction(
  '[Scenario/API] Add Scenario',
  props<{ scenario: Scenario }>()
);

export const upsertScenario = createAction(
  '[Scenario/API] Upsert Scenario',
  props<{ scenario: Scenario }>()
);

export const loadScenarios = createAction(
  '[Scenario/API] Load scenarios',
  props<{ scenarios: Scenario[], totalCount?: number;  }>()
);

export const addScenarios = createAction(
  '[Scenario/API] Add scenarios',
  props<{ scenarios: Scenario[] }>()
);

export const upsertScenarios = createAction(
  '[Scenario/API] Upsert scenarios',
  props<{ scenarios: Scenario[], totalCount?: number;  }>()
);

export const updateScenarioSettings = createAction(
  '[Scenario/API] Update Scenario Settings',
  props<{ scenarioUpdate: ScenarioUpdateDtoModel, trackingData?: any }>()
);

export const updateScenarioFavourite = createAction(
  '[Scenario/API] Update Scenario Favourite',
  props<{ scenario: Update<Scenario> }>()
);

export const updateScenarioSuccess = createAction(
  '[Scenario/API] Update Scenario Success',
  props<{ scenario: Update<Scenario> }>()
);

export const updateScenarios = createAction(
  '[Scenario/API] Update scenarios',
  props<{ scenarios: Update<Scenario>[] }>()
);

export const deleteScenarioById = createAction(
  '[Scenario/API] Delete Scenario By ID',
  props<{ id: number }>()
);

export const deleteScenarios = createAction(
  '[Scenario/API] Delete scenarios',
  props<{ ids: number[] }>()
);

export const deleteScenario = createAction(
  '[Scenario/API] Delete Scenario',
  props<{ scenario: Scenario; shouldRedirect?: boolean }>()
);

export const archiveScenario = createAction(
  '[Scenario/API] Archive scenario',
  props<{ scenario: Scenario; paginationParams: IGraphQLParams }>()
);

export const runScenario = createAction(
  '[Scenario/API] Run scenario',
  props<{ scenario: Scenario; }>()
);

export const cancelScenario = createAction(
  '[Scenario/API] Cancel Scenario By ID',
  props<{ id: number }>()
);

export const displayMessage = createAction(
  '[Scenario/API] Display Message',
  props<{ detail: string; summary: string; severity?: string; params?: Object }>()
);

export const loadBreadcrumbScenarios = createAction(
  '[Scenario/API] Paginate Scenarios',
  props<{ scenarios: Scenario[], totalCount?: number; }>()
);

export const clearScenarios = createAction(
  '[Scenario/API] Clear Scenarios'
);

export const updateScenarioConfig = createAction(
  '[Scenario/API] Update Scenario Config',
  props<{ scenario: Scenario }>()
);

export const scenarioUserEventLogged = createAction(
  '[Scenario] Scenario User Event Logged',
  props<{ scenarioUserEvent: IScenarioUserEventPayload }>()
);
