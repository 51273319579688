import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-breadcrumbs-dropdown-item',
  templateUrl: './breadcrumbs-dropdown-item.component.html',
  styles: [`:host { display: block; min-width: 100% }`],
})
export class BreadcrumbsDropdownItemComponent {
  @Input() userRole: string = '';
}
