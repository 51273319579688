<div class="flex items-center justify-between">
  <div class="flex items-center gap-1.5 text-sm">
    @if (!product) {
      <app-breadcrumbs-dropdown
        icon="home"
        [isProductRoute]="false"
        [isScenarioRoute]="false"
        [hasDropdownList]="false"
        [redirectTo]="['/products']">
        <span class="text-inherit font-medium" translate="global.home"></span>
      </app-breadcrumbs-dropdown>
      <span class="text-xs font-semibold opacity-30 px-1">›</span>
    }
    @if (breadcrumbsMenu$ | async; as breadcrumbsMenu) {
      @if (product) {
        @if (breadcrumbsMenu.products; as productsMenu) {
          <app-breadcrumbs-dropdown icon="puzzle-piece-solid" [isProductRoute]="!showScenarioListSelector"
            [isScenarioRoute]="showScenarioListSelector" [hasDropdownList]="productsMenu.menuItems.length">
            <span>{{ productsMenu.label | translate }}</span>
            <ng-container breadcrumbsBackToList>
              <a class="w-full py-2 px-3 flex gap-3.5 items-center text-inherit no-underline rounded-md hover:bg-soft-blue active font-medium"
                [routerLink]="['/products']">
                <app-svg-images class="h-3.5" svgName="arrow-uturn-left"></app-svg-images>
                <span translate="actions.gotoProducts"></span>
              </a>
            </ng-container>
            <ng-container breadcrumbsDropdown>
              @for (item of productsMenu.menuItems; track item.link) {
                <a class="active group/itemLink text-inherit"
                  [routerLink]="item.link" routerLinkActive="is-active bg-soft-blue font-medium">
                  <app-breadcrumbs-dropdown-item [userRole]="item?.role">{{ item.name }}</app-breadcrumbs-dropdown-item>
                </a>
              }
            </ng-container>
          </app-breadcrumbs-dropdown>
        }
        @if (breadcrumbsMenu.scenarios; as scenariosMenu) {
          <span class="text-xs font-semibold opacity-30 px-1">›</span>
          <app-breadcrumbs-dropdown icon="scenario" [hasDropdownList]="scenariosMenu.menuItems.length > 0">
            <span>{{ scenariosMenu.label | translate }}</span>
            <ng-container breadcrumbsBackToList>
              <a class="w-full py-2 px-3 flex gap-3.5 items-center text-inherit no-underline rounded-md hover:bg-soft-blue active font-medium"
                [routerLink]="['/products', scenariosMenu.productId ,'scenarios']">
                <app-svg-images class="h-3.5" svgName="arrow-uturn-left"></app-svg-images>
                <span translate="actions.gotoScenarios"></span>
              </a>
            </ng-container>
            <ng-container breadcrumbsDropdown>
              @for (item of scenariosMenu.menuItems; track item.link) {
                <a class="active group/itemLink text-inherit"
                  [routerLink]="item.link" routerLinkActive="is-active bg-soft-blue font-medium">
                  <app-breadcrumbs-dropdown-item [userRole]="item?.role">{{ item.name }}</app-breadcrumbs-dropdown-item>
                </a>
              }
            </ng-container>
          </app-breadcrumbs-dropdown>
        }
        @if (breadcrumbsMenu.reports; as reportsMenu) {
          @if(reportsMenu.label?.length > 0) {
            <span class="text-xs font-semibold opacity-30 px-1">›</span>
            <app-breadcrumbs-dropdown icon="report" [isProductRoute]="!showScenarioListSelector"
              [isScenarioRoute]="showScenarioListSelector" [hasDropdownList]="reportsMenu.menuItems.length > 0">
              <span>{{ reportsMenu.label | translate }}</span>
              <ng-container breadcrumbsDropdown>
                @for (item of reportsMenu.menuItems; track item.link) {
                  <a *HasAuthority="item?.role?.length ? 'ROLE_'+ item?.role : null" class="active group/itemLink text-inherit"
                    [routerLink]="['reports', item.link]" [relativeTo]="parentRoute"
                    routerLinkActive="is-active bg-soft-blue font-medium">
                    <app-breadcrumbs-dropdown-item [userRole]="item?.role">
                      {{ item.label | translate }}
                    </app-breadcrumbs-dropdown-item>
                  </a>
                }
              </ng-container>
            </app-breadcrumbs-dropdown>
          }
        }
      } @else {
        @if (breadcrumbsMenu.organisations; as organisationsMenu) {
          <app-breadcrumbs-dropdown icon="building-office-2" [hasDropdownList]="organisationsMenu.menuItems.length > 0"
            [isTotalItems]="organisationsMenu.total <= organisationsMenu.menuItems.length"
            (loadMore)="loadMoreOrgs(organisationsMenu.total)">
            <span>{{ organisationsMenu.label }}</span>
            <ng-container breadcrumbsBackToList>
              <a class="w-full py-2 px-3 flex gap-3.5 items-center text-inherit no-underline rounded-md hover:bg-soft-blue active font-medium"
                [routerLink]="['/company-management', organisationsMenu.orgId, 'organisations']">
                <app-svg-images class="h-3.5" svgName="arrow-uturn-left"></app-svg-images>
                <span translate="actions.gotoOrganisations"></span>
              </a>
            </ng-container>
            <ng-container breadcrumbsDropdown>
              @for (item of organisationsMenu.menuItems; track item.link) {
                <a class="active group/itemLink text-inherit"
                  [routerLink]="item.link" routerLinkActive="is-active bg-soft-blue font-medium">
                  <app-breadcrumbs-dropdown-item>{{ item.name }}</app-breadcrumbs-dropdown-item>
                </a>
              }
            </ng-container>
          </app-breadcrumbs-dropdown>
          @if (breadcrumbsMenu.users) {
            <span class="text-xs font-semibold opacity-30 px-1">›</span>
          }
        }
        @if (breadcrumbsMenu.users; as usersMenu) {
          <app-breadcrumbs-dropdown icon="user" [hasDropdownList]="usersMenu.menuItems.length > 0"
            [isTotalItems]="usersMenu.total <= usersMenu.menuItems.length"
            (loadMore)="loadMoreUsers(usersMenu.orgId, usersMenu.total)">
            <span>{{ usersMenu.label }}</span>
            <ng-container breadcrumbsBackToList>
              <a class="w-full py-2 px-3 flex gap-3.5 items-center text-inherit no-underline rounded-md hover:bg-soft-blue active font-medium"
                [routerLink]="[usersMenu.orgRoute]" [relativeTo]="parentRoute">
                <app-svg-images class="h-3.5" svgName="arrow-uturn-left"></app-svg-images>
                <span translate="actions.gotoUsers"></span>
              </a>
            </ng-container>
            <ng-container breadcrumbsDropdown>
              @for (item of usersMenu.menuItems; track item.link) {
                <a class="active group/itemLink text-inherit"
                  [routerLink]="item.link" routerLinkActive="is-active bg-soft-blue font-medium">
                  <app-breadcrumbs-dropdown-item>{{ item.name }}</app-breadcrumbs-dropdown-item>
                </a>
              }
            </ng-container>
          </app-breadcrumbs-dropdown>
        }
        @if (breadcrumbsMenu.reports; as reportsMenu) {
          <app-breadcrumbs-dropdown icon="report" [hasDropdownList]="reportsMenu.menuItems.length > 0">
            <span>{{ reportsMenu.label | translate }}</span>
            <ng-container breadcrumbsBackToList>
              <a class="w-full py-2 px-3 flex gap-3.5 items-center text-inherit no-underline rounded-md hover:bg-soft-blue active font-medium"
                [routerLink]="['/']">
                <app-svg-images class="h-3.5" svgName="arrow-uturn-left"></app-svg-images>
                <span translate="global.home"></span>
              </a>
            </ng-container>
            <ng-container breadcrumbsDropdown>
              @for (item of reportsMenu.menuItems; track item.id) {
                <a class="active group/itemLink text-inherit"
                  [routerLink]="[item.id]" [relativeTo]="parentRoute"
                  routerLinkActive="is-active bg-soft-blue font-medium">
                  <app-breadcrumbs-dropdown-item>
                    {{ item.title }}
                  </app-breadcrumbs-dropdown-item>
                </a>
              }
            </ng-container>
          </app-breadcrumbs-dropdown>
        }
      }
    }
  </div>
</div>

