import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { ProductBlendedSegmentNode } from 'src/app/views/scenario/settings/models/config.models';
import { FlagPipe } from 'src/app/views/scenario/pipes/flag.pipe';
import { TooltipDirective } from '../../directives/tooltip.directive';
import { NgClass, NgStyle } from '@angular/common';

export interface SegmentParts {
  country: string;
  platform: string;
  other: string[];
}

@Component({
  selector: 'app-segment-label',
  templateUrl: './segment-label.component.html',
  imports: [FlagPipe, TooltipDirective, NgClass, NgStyle],
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SegmentLabelComponent {
  @Input() segment: ProductBlendedSegmentNode;
  @Input() truncate: boolean = true;
  @Input() maxWidth: string = '90';
  @Input() totalSegments: number = 5;

  segmentParts(): SegmentParts {
    if (!this.segment) return;
    const [country, platform, ...other] = this.segment.name.split('|');
    return { country, platform, other };
  }
}
