import { createFeature, createReducer, on } from '@ngrx/store';
import { ReportingActions } from '../actions/reporting.actions';
import { Helpers } from "../../core/shared/helpers";
import { INCLUDED_FILTERS } from "../../core/app.constants";
import {
  ReportMenuItem
} from 'src/app/views/product/nav/product-dashboard-sidenav/product-dashboard-sidenav.component';

export const reportingFeatureKey = 'reporting';

export interface Dashboard {
  id: number
  title: string
  viewCount: number
}

export interface ReportsState {
  favourites: ReportMenuItem[];
  reports: Dashboard[];
  personal: Dashboard[];
  filters: object;
  loading: boolean;
  initialFilters: object;
}

export const initialState: ReportsState = {
  favourites: [],
  reports: [],
  personal: [],
  loading: false,
  filters: {},
  initialFilters: {}
};

export const reducer = createReducer(
  initialState,
  on(ReportingActions.loadReports, state => {
    return {
      ...state,
      loading: true
    }
  }),
  on(ReportingActions.updateFilters, (state, action) => {
    const filters = Helpers.pluckKeys(INCLUDED_FILTERS, { ...state.filters, ...action.filters });
    return {
      ...state,
      filters
    }
  }),
  on(ReportingActions.setDefaultFilters, (state, action) => {
    const filters = action.filters
    return {
      ...state,
      initialFilters: {
        ...state.initialFilters,
        [action.report]: filters
      }
    }
  }),
  on(ReportingActions.resetFilters, (state, action) => {
    return {
      ...state,
      filters: {}
    }
  }),
  on(ReportingActions.loadReportsSuccess, (state, action) => {
    return {
      ...state,
      reports: action.group,
      personal: action.personal,
      loading: false
    }
  }),
  on(ReportingActions.loadReportsFailure, (state, action) => {
    return {
      ...state,
      loading: false
    }
  }),
  on(ReportingActions.fetchFavouritesSuccess, (state, action) => {
    return {
      ...state,
      favourites: action.list
    }
  }),
  on(ReportingActions.deleteFavouriteSuccess, (state, action) => {
    return {
      ...state,
      favourites: state.favourites.filter(favourite => favourite.label !== action.label)
    }
  })
);

export const reportingFeature = createFeature({
  name: reportingFeatureKey,
  reducer,
});

