<div class="flex items-center justify-between w-full">
    <a routerLink="/">
        <img data-cy="logo" alt="Kohort logo"
        [src]="(isDarkMode$ | async) ? '//kohort-media.s3.eu-west-1.amazonaws.com/control/logo-white.png': '//kohort-media.s3.eu-west-1.amazonaws.com/control/logo.png'"
        class="w-auto h-7 active"/>
    </a>
    <div class="flex-fill"></div>
    <app-theme-switcher class="mr-0" />
    <app-user-orgs-menu></app-user-orgs-menu>
</div>
