import { createAction, props } from '@ngrx/store';
import { Update } from '@ngrx/entity';

import { Activity } from './activity.model';

export const fetchActivities = createAction(
  '[Activity/API] Fetch activities'
);

export const loadActivities = createAction(
  '[Activity/API] Load activities',
  props<{ activities: Activity[] }>()
);

export const addActivity = createAction(
  '[Activity/API] Add Activity',
  props<{ activity: Activity }>()
);

export const upsertActivity = createAction(
  '[Activity/API] Upsert Activity',
  props<{ activity: Activity }>()
);

export const addActivities = createAction(
  '[Activity/API] Add activities',
  props<{ activities: Activity[] }>()
);

export const upsertActivities = createAction(
  '[Activity/API] Upsert activities',
  props<{ activities: Activity[] }>()
);

export const updateActivity = createAction(
  '[Activity/API] Update Activity',
  props<{ activity: Update<Activity> }>()
);

export const updateActivities = createAction(
  '[Activity/API] Update activities',
  props<{ activities: Update<Activity>[] }>()
);

export const deleteActivity = createAction(
  '[Activity/API] Delete Activity',
  props<{ id: string }>()
);

export const deleteActivities = createAction(
  '[Activity/API] Delete activities',
  props<{ ids: string[] }>()
);

export const clearActivities = createAction(
  '[Activity/API] Clear Activitys'
);

export const toggleSidebar = createAction(
  '[Activity/API] Toggle Sidebar',
  props<{ orgId: string }>()
);

export const markAsRead = createAction(
  '[Activity/API] Mark Activities As Read',
  props<{ unread: Activity[], isRead?: boolean }>()
);

export const saveUnreadNotifications = createAction(
  '[Activity/API] Save Unread Notifications',
  props<{ unread: Activity[], orgId: string }>()
  );

  export const restoreUnreadState = createAction(
    '[Activity/API] Restore Unread Notifications',
    props<{ orgId: string }>()
);

export const deleteScenarioJobsActivity = createAction(
  '[Activity/API] Delete Notifications',
  props<{ scenarioId?: number, productId?: number }>()
);

export const setUpdateAvailableActivity = createAction(
  '[Activity/API] Update Available Notification'
);
