<div class="flex pb-3 pt-2" [ngClass]="{'items-center justify-between': inline, 'flex-col': !inline}">
  @if (headerDetails$ | async; as details) {
    <div class="space-y-2">
      <h3 data-cy="page-header-title" class="font-medium text-xl leading-none pr-3 font-title" [translate]="details.title"></h3>
      @if (details.description) {
        <h3 data-cy="page-header-description" class="text-sm text-gray-500 dark:text-white/50 fade-in" [translate]="details.description"></h3>
      }
    </div>
  }
  <div class="flex items-center justify-end gap-2">
    <ng-content></ng-content>
  </div>
</div>
