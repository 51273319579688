<div
  class="flex flex-col relative text-center items-center z-50 w-full box-border bg-white p-6 max-w-md shadow-small overflow-y-hidden font-body rounded-md">
  <div class="space-y-4">
    <div class="flex items-center justify-center gap-6">
      <h2 class="font-medium text-lg">{{ dialogData.title }}</h2>
      <app-close-button (click)="dialogRef.close(false)" data-cy="confirmation-modal-x-button" class="absolute top-0 right-0"></app-close-button>
    </div>
    <div class="space-y-1.5">
      <div [innerHTML]="dialogData.message"></div>
    </div>
    <div class="flex items-center justify-center gap-8">
      <app-button variant="primary" type="button"
        (click)="dialogRef.close(true)">
        {{ dialogData.action }}
      </app-button>
    </div>
  </div>
</div>
