import { MatDateFormats } from '@angular/material/core';
import { AppConfig, IPandasDateRange } from './config/models/config';
import { createAction } from '@ngrx/store';
import { InjectionToken } from "@angular/core";

export const KOHORT = '                             \n' +
  ' _  ______  _    _  ____  _____ _______ \n' +
  '| |/ / __ \\| |  | |/ __ \\|  __ \\__   __|\n' +
  '|   / |  | | |__| | |  | | |__) | | |\n' +
  '|  <| |  | |  __  | |  | |  _  /  | |\n' +
  '| . \\ |__| | |  | | |__| | | \\ \\  | |\n' +
  '|_|\\_\\____/|_|  |_|\\____/|_|  \\_\\ |_|';
export const KOHORT_COMPANY_NAME = 'Kohort';

export const SIDE_BAR_LOCAL_STORAGE_KEY = 'isSideNavOpen';
export const USER_ROLE_LOCAL_STORAGE_KEY = 'userRole';
export const INTERNAL_USER_LOCAL_STORAGE_KEY = 'isInternalUser';

export const APP_DATE_FORMATS: MatDateFormats = {
  parse: {
    dateInput: 'YYYY-MM-DD',
  },
  display: {
    dateInput: 'YYYY-MM-DD',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};

export enum reportType {
  NEW_USERS = 'NEW_USERS',
  RETENTION = 'RETENTION',
  MONETISATION = 'MONETISATION',
}

export const HOME_ROUTE = '/products';
export const USER_MANAGEMENT_ROUTE = '/company-management';

export const AUTH0_ID_PREFIX = 'auth';

export const VALID_PANDAS_DATE_RANGE: IPandasDateRange = {
  min: new Date(1678, 0, 1),
  max: new Date(2261, 11, 31)
};

export const LAST_24_HOURS = new Date(new Date().getTime() - 24 * 60 * 60 * 1000).toISOString();

export const PROCESSED_STATES = [
  'PROCESSED',
  'FAILED',
  'ERROR',
  'CANCELLED',
  'NEW'
];

export const INCLUDE_MOMENT_START_DATE = 1;

export const DEFAULT_PAGE_SIZE = 20;
export const DEFAULT_CURRENT_PAGE = 1;
export const DEFAULT_LOADING_PAGE_SIZE = 10;

export const FAVOURITE_SCENARIOS_LIMIT = 10;

export const MODAL_DEFAULT_WIDTH = '427px';

export const OVERRIDE_DATE_FORMATS = ['YYYY/MM/DD', 'YYYY-MM-DD'];

export const MAXIMUM_FILE_UPLOAD_SIZE = 209715200; // 200 Mb in bytes : https://unitconverter.io/megabytes/bytes/200

export const FIRST_TIME_LOGIN_COUNT = 1;

export const COHORTISED_ARPDAU_LOOKBACK_LIMIT = 15; // days > 14
export const LOOKER_PRODUCT_SEGMENT_LIMIT = 50;

export const LOGIN_ERROR_CODES = [401, 403, 408, 500, 503];

export const SEGMENTS_COLUMNS_OVERVIEW = ['segmentName', 'size', 'state', 'processingStart', 'processingEnd', 'message'];
export const SEGMENTS_COLUMNS_NEW_USERS = ['segmentName', 'size', 'state', 'newUsers', 'newUsersModel', 'newUsersLookback', 'message'];
export const SEGMENTS_COLUMNS_MONITISATION = ['segmentName', 'size', 'state', 'monetisation', 'monetisationModel', 'monetisationLookback', 'message'];
export const SEGMENTS_COLUMNS_RETENTION = ['segmentName', 'size', 'state', 'retention', 'dauMultiplier', 'retentionModel', 'retentionLookback', 'message'];

export const REPORT_TEMPLATE = `
<div class="h-full">
  <app-looker-granularity [hasGranularity]="granularity" [scenario]="scenario" [chart]="dashboard"></app-looker-granularity>
</div>


`
export const REPORTS_BY_SEGMENT_ROUTES = [
  'new-users',
  'retention-curve',
  'daily-active-users',
  'user-revenue',
  'cumulative-lifetime-value',
  'lifetime-value',
  'revenue',
  'cost-per-acquisition',
  'marketing-spend',
];

export const THEMEABLE_URL_PATTERNS = [
  /\/reports/,
  /^\/products\/\d+\/scenarios$/,
  /^\/products\/\d+\/scenarios\/internal$/,
  /^\/products\/\d+\/scenarios\/\d+\/overview$/,
  /^\/products\/\d+\/overview$/,
];

export const APP_INITIALIZED = createAction('[App] App Initialized')

export const APP_CONFIG = new InjectionToken<AppConfig>('APP_CONFIG')

export const INCLUDED_FILTERS = ['Date Range', 'Cohort Date Range', 'Calendar Date Range', 'Segment', 'Country', 'Platform', 'Network', 'Channel']

export const EXCLUDED_FILTERS = ['Days Since Install']

export const SHOW_INTERNAL_FILTER = 'Show Internal';

export const DEFAULT_DATE_RANGES = {
  'Daily': {
    'Date Range': '12 months ago for 24 months'
  },
  'Other': {
    'Date Range': '1 year ago for 4 years'
  },
}

export const PRODUCT_RESET_FILTERS = ['Product', 'Baseline', 'Budget']
export const SCENARIO_RESET_FILTERS = ['Scenario']
