import { Component, EventEmitter, Input, Output } from '@angular/core';
import { CloseButtonComponent } from '../close-button/close-button.component';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'app-dialog',
  standalone: true,
  template: `
    <div class="flex flex-col relative z-50 max-h-[95vh] w-full p-6 box-border bg-white shadow-small overflow-y-hidden font-body rounded-md">
      <div class="space-y-4 overflow-y-auto h-full">
          <header class="flex items-center justify-between gap-6 text-lg ml-2 mb-2">
              <ng-content select="[custom-dialog='header']"></ng-content>
              <app-close-button (click)="close.emit()"></app-close-button>
          </header>

          <section class="space-y-1.5">
              <ng-content select="[custom-dialog='content']"></ng-content>
          </section>

          @if(showActions) {
            <footer class="flex items-center justify-end gap-8 mb-1 mr-1">
                <button type="button" class="font-medium hover:underline active text-sm" translate="global.cancel"
                    (click)="close.emit()">
                </button>
                <ng-content select="[custom-dialog='actions']"></ng-content>
            </footer>
          }
      </div>
  </div>
  `,
  imports: [CloseButtonComponent, TranslateModule],
  styles: `
    :host {
      display: block;
    }
  `
})
export class DialogComponent {
  @Input() showActions: boolean = true;
  @Output() close = new EventEmitter<void>();
}
