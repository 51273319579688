<div class="inline-flex group"
  (mouseenter)="toggleNavGroup(true)"
  (mouseleave)="toggleNavGroup(false)"
  (click)="toggleGroup()"
  [class.overlay-popup]="isOpenState()"
  cdkOverlayOrigin
  #trigger="cdkOverlayOrigin">
  <ng-content></ng-content>
</div>

<ng-template
  cdkConnectedOverlay
  [cdkConnectedOverlayOrigin]="trigger"
  [cdkConnectedOverlayOpen]="isOpenState()"
  [cdkConnectedOverlayPositions]="getPosition(position)"
  [cdkConnectedOverlayViewportMargin]="20"
  [cdkConnectedOverlayPush]="true"
  (overlayOutsideClick)="closeMenu()">

  <div class="relative" cdkTrapFocus [cdkTrapFocusAutoCapture]="true"
    (mouseenter)="onMouseEnterMenu()"
    (mouseleave)="onMouseLeaveMenu()">
    @if (enableClick) {
      <button type="button" (click)="closeMenu()"
        class="absolute top-3 right-3 z-10 order rounded-md shadow-sm flex items-center justify-center p-2 active hover:text-white text-gray hover:border-digital-blue-700 hover:bg-digital-blue">
        <app-svg-images class="w-3 h-3 block" svgName="x-mark"></app-svg-images>
      </button>
    }
    <section appResize (onResize)="handleResize($event)">
      <ng-content select="[popup-content]"></ng-content>
    </section>
  </div>
</ng-template>