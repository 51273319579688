@let otherTotal = totalSegments - 2; <!-- exclude country and platform -->
@if (segmentParts(); as segment) {
  <div
    class="text-sm inline-flex border rounded-md hover:shadow-sm divide-x bg-gray-50/70 divide-gray-100 overflow-hidden border-gray-300">
    <div class="py-1.5 px-2 flex items-center last:bg-white gap-1.5 font-medium">
      @if (segment.country) {
        <span [class]="segment.country | flag"></span>
      }
      {{segment.country}}
    </div>
    <div
      class="py-1.5 px-2 flex items-center last:bg-white gap-1.5 font-medium">
      {{segment.platform}}
    </div>

    @for (other of segment.other; track other; let idx = $index) {
      @if(idx < otherTotal) {
        <div data-cy="other-segment-part"
          class="py-1.5 px-2 flex items-center last:bg-white gap-1.5 font-medium">
          <span [appTooltip]="other" [tooltipAutoToggle]="true"
            [ngClass]="{'overflow-hidden block text-ellipsis': truncate}"
            [ngStyle]="{'max-width': maxWidth + 'px'}">
            <span data-tooltip-text class="break-normal">
              {{other}}
            </span>
          </span>
        </div>
      }
    }
    @if(segment.other.length > otherTotal) {
      <div data-cy="other-segment-part" [appTooltip]="segment.other.slice(otherTotal).join(', ')"
        class="py-1.5 px-2 flex items-center last:bg-white gap-1.5 font-medium">
        ...
      </div>
  }
  </div>
}