<span class="inline-flex items-center rounded-md text-xs font-medium ring-1 ring-inset"
      [ngClass]="{
        default : 'bg-teal-green-30 px-2 py-1 ring-transparent dark:text-gray-500',
        primary : 'bg-kohort-primary text-white py-0.5 px-1.5 ring-kohort-primary',
        archived: 'bg-yellow-800 text-white py-0.5 px-1.5 no-underline ring-transparent',
        budget : 'bg-blue-100 text-gray-700 px-2 py-1 ring-transparent',
        internal : 'text-white bg-kohort-primary px-1 py-0.5 rounded text-xs font-medium',
        info: 'bg-digital-blue text-white py-0.5 px-1.5 no-underline ring-transparent',
        warning: 'bg-yellow-500 text-white py-0.5 px-1.5 no-underline ring-transparent',
        error: 'bg-red-500 text-white py-0.5 px-1.5 no-underline ring-transparent',
        other: 'bg-kohort-primary/70 text-white py-0.5 px-1.5 no-underline ring-transparent'
      }[variant]">
    <ng-content></ng-content>
</span>
