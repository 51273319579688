import { createReducer, on } from '@ngrx/store';
import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { Activity, ActivityType } from './activity.model';
import * as ActivityActions from './activity.actions';
import { setUpdateAvailableActivity } from "./activity.actions";

export const activitiesFeatureKey = 'activities';

export interface ActivityState extends EntityState<Activity> {
  isSidebarOpen: boolean;
  read: boolean;
}

export function sortByDate(e1: Activity, e2: Activity) {
  const toTimeStamp = (date: string): number => Date.parse(date);
  return toTimeStamp(e2?.createdAt) - toTimeStamp(e1?.createdAt);
}

export const adapter: EntityAdapter<Activity> = createEntityAdapter<Activity>(
  {
    sortComparer: sortByDate
  }
);

export const initialState: ActivityState = adapter.getInitialState({
  isSidebarOpen: false,
  read: false,
});

export const reducer = createReducer(
  initialState,
  on(ActivityActions.addActivity,
    (state, action) => adapter.addOne(action.activity, state)
  ),
  on(ActivityActions.upsertActivity,
    (state, action) => adapter.upsertOne(action.activity, state)
  ),
  on(ActivityActions.addActivities,
    (state, action) => adapter.addMany(action.activities, state)
  ),
  on(ActivityActions.upsertActivities,
    (state, action) => adapter.upsertMany(action.activities, state)
  ),
  on(ActivityActions.updateActivity,
    (state, action) => adapter.updateOne(action.activity, state)
  ),
  on(ActivityActions.updateActivities,
    (state, action) => adapter.updateMany(action.activities, state)
  ),
  on(ActivityActions.deleteActivity,
    (state, action) => adapter.removeOne(action.id, state)
  ),
  on(ActivityActions.deleteActivities,
    (state, action) => adapter.removeMany(action.ids, state)
  ),
  on(ActivityActions.loadActivities,
    (state, action) => adapter.setAll(action.activities, state)
  ),
  on(ActivityActions.clearActivities,
    state => adapter.removeAll(state)
  ),
  on(ActivityActions.toggleSidebar,
    state => ({ ...state, isSidebarOpen: !state.isSidebarOpen })
  ),
  on(ActivityActions.markAsRead,
    (state, action) => ({ ...state, read: action.isRead })
  ),
  on(ActivityActions.setUpdateAvailableActivity,
    (state, action) => adapter.upsertOne({
      errors: null,
      message: "",
      updatedBy: "",
      id: -1,
      createdAt: new Date().toISOString(),
      read: false,
      type: ActivityType.updateAvailable,
    }, state)
  )
);

export const {
  selectIds,
  selectEntities,
  selectAll,
  selectTotal,
} = adapter.getSelectors();
