import { ConfirmationDialogComponent } from '../ui/confirmation-dialog/confirmation-dialog.component';
import { TranslateService } from '@ngx-translate/core';
import { Injectable } from '@angular/core';
import { UpdateAppDialogueComponent } from '../ui/update-app-dialogue/update-app-dialogue.component';
import { Dialog } from '@angular/cdk/dialog';

@Injectable({ providedIn: 'root' })
export class ConfirmationService {
  constructor(
    private dialog: Dialog,
    private translateService: TranslateService
  ) {}

  confirm(
    accept: () => void,
    decline?: () => void,
    message: string | any = 'data.scenario.delete',
    title: string = 'data.scenario.deleteTitle',
    action: string = 'data.scenario.yes',
    icon: string = '',
    {translateParams = {}} = {}
  ) {
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: '350px',
      data: {
        message: typeof message === 'string' ? this.translateService.instant(message, translateParams) : message,
        title: this.translateService.instant(title),
        action: this.translateService.instant(action),
        icon: icon
      },
    });

    dialogRef.closed.subscribe((result) => {
      if (result) {
        accept();
      } else {
        if (typeof decline === 'function') {
          decline();
        }
      }
    });
  }

  updateWebapp(
    accept: () => void,
    title = 'data.updateTitle',
    message = 'data.update',
    action = 'data.action',
    decline?: () => void,
  ) {
    const dialogRef = this.dialog.open(UpdateAppDialogueComponent, {
      width: '350px',
      data: {
        message: this.translateService.instant(message),
        title: this.translateService.instant(title),
        action: this.translateService.instant(action),
      },
      disableClose: false,
    });

    dialogRef.closed.subscribe((result) => {
      if (result) {
        accept();
      } else {
        decline();
      }
    });
  }
}
