<button [cdkMenuTriggerFor]="menu" type="button" class="w-full outline-none active text-sm flex items-center justify-between p-2 rounded-md dark:hover:bg-secondary dark:bg-slate-950 dark:text-white/70 dark:border-slate-800/50 hover:bg-soft-blue dark:hover:text-white active hover:rotate-6">
  <div class="h-5 w-5 rounded-md">
    @if (currentOrganisation?.logo?.length > 0) {
      <img [src]="currentOrganisation?.logo" class="h-full rounded-sm">
    } @else {
      <div class="bg-secondary p-1 rounded border border-white/20 flex items-center justify-center">
        <app-svg-images class="h-3" svgName="building-office-2"></app-svg-images>
      </div>
    }
  </div>
</button>
<ng-template #menu>
  @if (userOrganisations?.length) {
    <div
      class="bg-white rounded-lg border shadow-md p-1.5 text-black fade-in divide-y divide-gray-200/70 max-h-screen overflow-y-auto text-sm"
      cdkMenu>
      @for (org of userOrganisations; track org.id) {
        <button (click)="switchOrganisationEvent(org)"
          class="flex items-center gap-3 py-3 px-4 w-full hover:bg-soft-blue hover:text-digital-blue active"
          cdkMenuItem>
          @if (org.logo?.length > 1) {
            <img [src]="org.logo" class="h-5 rounded">
          } @else {
            <div class="bg-secondary p-1 rounded border border-white/20 flex items-center justify-center">
              <app-svg-images class="h-3" svgName="building-office-2"></app-svg-images>
            </div>
          }
          <span> {{ org.name }}</span>
        </button>
      }
    </div>
  }
</ng-template>
