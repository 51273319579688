import { Action, createReducer, on } from '@ngrx/store';
import * as PageActions from '../actions/page.actions';

export interface PageState {
  pageTitle: string;
}

export const initialState: PageState = {
  pageTitle: null,
};

export const pageReducer = createReducer(
  initialState,
  on(PageActions.setPageHeaderContent, (state, action) => {
    return { ...state, ...action };
  })
);

export function reducer(state: PageState | undefined, action: Action) {
  return pageReducer(state, action);
}
