import { createFeatureSelector, createSelector } from '@ngrx/store';
import * as fromReporting from '../reducers/reporting.reducer';
import { selectAllScenarios } from "./scenario.selectors";
import { MarketingMode, ScenarioMarketingConfig } from "../../views/scenario/settings/models/config.models";
import { Helpers } from 'src/app/core/shared/helpers';
import { Scenario, ScenarioType } from "../../views/scenario/model/scenario.model";
import { selectPageHeaderTitle } from './page.selectors';
import { DEFAULT_DATE_RANGES } from 'src/app/core/app.constants';

export const selectReportingState = createFeatureSelector<fromReporting.ReportsState>(
  fromReporting.reportingFeatureKey
);

export const selectReports = createSelector(
  selectReportingState,
  (state: fromReporting.ReportsState) => state.reports
);

export const selectPersonalReports = createSelector(
  selectReportingState,
  (state: fromReporting.ReportsState) => state.personal
);

export const selectAllReports = createSelector(
  selectReportingState,
  (state: fromReporting.ReportsState) => [...state.reports, ...state.personal]
);

export const selectReport = (id: number) => createSelector(
  selectAllReports,
  (reports) => reports.find(report => report.id.toString() === id.toString())
);

export const loadingReportsState = createSelector(
  selectReportingState,
  (state) => state.loading
);

export const selectFilters = createSelector(
  selectReportingState,
  (state: fromReporting.ReportsState) => state.filters
);

export const selectDefaultFilters = (report: string) => createSelector(
  selectReportingState,
  (state: fromReporting.ReportsState) => state.initialFilters[report] ?? {}
);

export const selectFavouriteReports = createSelector(
  selectReportingState,
  (state: fromReporting.ReportsState) => {
    return new Map(state.favourites.map((favourite) => [favourite.label, favourite.label]));
  }
);

export const selectScenarioById = (id: number) => createSelector(
  selectAllScenarios,
  (scenarios) => scenarios.find(scenario => scenario.id === id) ?? new Scenario(id),
);

/**
 * Custom filters per dashboard
 * @param dashboard
 * @param id
 * @param scenarios
 */
export const selectFiltersByDashboardAndScenarioId = (dashboard: string, id: number, scenarios: Scenario[]) => createSelector(
    selectScenarioById(id),
    selectFilters,
    (state, filters) => {

      if (['scenario_non_daily', 'scenario'].includes(dashboard)) {
        return (id && {
          ...(state.config?.marketingV2?.mode === MarketingMode.TARGET_PAYBACK && {
            'LTV DX': state.config.marketingV2.targetPayback,
            'ROAS DX': [7, state.config.marketingV2.targetPayback, 365].sort((a, b) => a - b).join(',')
          }),
        })
      }

      if ('spend_recommendations' === dashboard) {
        return ((id && !!state.config?.marketingV2 && !!state.config?.inputRange?.start) && {
          'Target Dx': ScenarioMarketingConfig.isTargetROAS(state.config?.marketingV2?.mode) ?
            state.config.marketingV2.targetPayback : 365,
          'Actuals Lookback': ScenarioMarketingConfig.getPVLookbackDays(state.config?.marketingV2.spendLookback, state.config?.inputRange?.start),
        })
      }

      if ('segment_checker' === dashboard) {
        return ((id && !!state.config?.marketingV2 && !!state.config?.inputRange?.start) && {
          'Actuals Lookback': ScenarioMarketingConfig.getPVLookbackDays(state.config?.marketingV2.spendLookback, state.config?.inputRange?.start),
        })
      }

      if (['budget_charts'].includes(dashboard)) {
        // select baseline and budget scenario
        const baseline = scenarios.find(scenario => scenario.default);
        const budget = scenarios.find(scenario => scenario.budget);
        return {
          'Baseline': baseline?.id,
          'Budget': budget?.id,
        }
      }

      if ('retention_curve_segmented' === dashboard && !!state.config?.inputRange?.end) {
        return Helpers.getRetentionCurveFilter(state.config.inputRange.end);
      }

      if ('budget_kpis' === dashboard) {
        const hasForecast = scenarios.some(scenario => scenario.type === ScenarioType.REFORECAST);
        return {
          'Type': (hasForecast) ? `${ ScenarioType.REFORECAST }, ${ ScenarioType.BUDGET }` : `${ ScenarioType.DEFAULT }, ${ ScenarioType.BUDGET }`
        }
      }

      if ('portfolio_budget_comparison' === dashboard) {
        const hasForecast = scenarios.some(scenario => scenario.type === ScenarioType.REFORECAST);
        return {
          'Scenario Type': (hasForecast) ? `${ ScenarioType.REFORECAST }, ${ ScenarioType.BUDGET }` : `${ ScenarioType.DEFAULT }, ${ ScenarioType.BUDGET }`
        }
      }

      if ('roas' === dashboard) {
        return ((id && !!state.config?.marketingV2 && !!state.config?.inputRange?.start) &&
          {
            'Current': ScenarioMarketingConfig.getPVLookbackDays(state.config?.marketingV2.spendLookback, state.config?.inputRange?.start),
            'Date Range': DEFAULT_DATE_RANGES.Daily['Date Range']
          }
        )
      }

      return {}
    }
  )
;
