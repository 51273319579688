import { NgClass } from '@angular/common';
import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-badge',
  templateUrl: './badge.component.html',
  standalone: true,
  imports: [NgClass]
})
export class BadgeComponent {
  @Input() variant: 'info' | 'warning' | 'error' | 'other' | 'primary' | 'default' | 'archived' | 'budget' | 'internal' = 'primary';
}
