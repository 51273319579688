import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { ActivityState, selectAll, setScenarioJob, toggleInternalData } from '../../../store';
import * as fromActivities from './activity.actions';
import { filter, map, mergeMap, switchMap, tap, withLatestFrom } from 'rxjs/operators';
import { EventType } from '../../services/event.service';
import { Action, Store } from '@ngrx/store';
import { ScenarioService } from '../../../views/scenario/services/scenario.service';
import { APP_INITIALIZED, INTERNAL_USER_LOCAL_STORAGE_KEY, LAST_24_HOURS, PROCESSED_STATES } from '../../app.constants';
import { SCENARIO_JOB_SCENARIO_QUERY } from '../../../views/scenario/graphql/queries';
import { HelpersService } from '../../services/helpers.service';
import { selectActivitySideBar, selectActivityState } from './activity.selectors';
import { Activity, ActivityType } from './activity.model';

@Injectable()
export class ActivityEffects {
  private excludedStates: string[] = ['NEW', 'ERROR'];

  constructor(private actions$: Actions,
              private scenarioService: ScenarioService,
              private helpersService: HelpersService,
              private store: Store<ActivityState>) {
  }

  scenarioJobListener$ = createEffect(() =>
    this.actions$.pipe(
      ofType(setScenarioJob),
      map(({scenarioJob}) => scenarioJob),
      filter((scenarioJob) => Boolean(scenarioJob) && !this.excludedStates.includes(scenarioJob?.state)),
      filter((job) => {
        const internalUser = this.helpersService.getFromLocalStorage(INTERNAL_USER_LOCAL_STORAGE_KEY).isInternal;
        return (internalUser === false) ? job.scenario.internal === false : true;
      }),
      withLatestFrom(this.store.select(selectActivityState)),
      map(([scenarioJob, state]) => fromActivities.upsertActivity({
          activity: {
            id: scenarioJob.id,
            type: ActivityType.scenarioJobUpdated,
            message: scenarioJob.state,
            eventName: ActivityType.scenarioJobUpdated,
            read: !(!state.isSidebarOpen && PROCESSED_STATES.includes(scenarioJob.state)),
            actionedBy: scenarioJob.actionedBy,
            updatedBy: scenarioJob.updatedBy,
            createdAt: scenarioJob.createdAt,
            completedAt: scenarioJob.completedAt,
            errors: scenarioJob.errors,
            data: {
              ...scenarioJob.scenario,
              currentScenarioJob: scenarioJob
            }
          }
        })
      )
    )
  );

  switchOrgNotification$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActivities.restoreUnreadState),
      map(({orgId}) => {
          const {unread} = this.helpersService.getFromLocalStorage(`unread-${orgId}`);
          return fromActivities.upsertActivities({
            activities: unread?.map(activity => ({...activity, read: false}))
          })
        }
      )
    )
  );

  deleteNotification$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActivities.deleteScenarioJobsActivity),
      withLatestFrom(this.store.select(selectActivityState).pipe(map(state => selectAll(state)))),
      map(([payload, state]) => {
          const {scenarioId, productId} = payload;
          const deletedScenaroActivityId = state.filter(activity => activity.data.id === scenarioId)?.map(activity => activity.id.toString());
          const deletedProductActivityId = state.filter(activity => activity.data.product.id === productId)?.map(activity => activity.id.toString());
          const ids = Boolean(scenarioId) ? deletedScenaroActivityId : deletedProductActivityId;
          return fromActivities.deleteActivities({ids})
        }
      )
    )
  );

  saveUnreadNotifications$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActivities.saveUnreadNotifications),
      withLatestFrom(this.store.select(selectActivityState)),
      tap(([payload, state]) => {
        const {orgId, unread} = payload;
        if (orgId === state.entities[state.ids[0]]?.data?.orgId) {
          this.helpersService.saveToLocalStorage(`unread-${orgId}`, {unread});
        }
      })
    ), {dispatch: false}
  );

  toggleSideBar$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActivities.toggleSidebar),
      tap(({orgId}) => {
        if (!!orgId && !!this.helpersService.getFromLocalStorage(`unread-${orgId}`)?.unread?.length) {
          this.helpersService.deleteFromLocalStorage(`unread-${orgId}`)
        }
      })
    ), {dispatch: false}
  );

  markAsRead$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActivities.markAsRead),
      map(({unread, isRead}) => {
        if (isRead && unread[0]?.data?.orgId) {
          this.helpersService.deleteFromLocalStorage(`unread-${unread[0]?.data?.orgId}`)
        }
        return fromActivities.upsertActivities({
          activities: unread.map(activity => ({...activity, read: isRead}))
        });
      })
    )
  );

  loadScenarioJobs$ = createEffect(() =>
    this.actions$.pipe(
      ofType(APP_INITIALIZED, toggleInternalData),
      mergeMap((action) => this.scenarioService.getJobs({
          page: 1, pageSize: 50, sort: ['CREATED_AT_DESC'],
          filter: {
            stateNotIn: this.excludedStates,
            createdAtGte: LAST_24_HOURS
          }
        }, SCENARIO_JOB_SCENARIO_QUERY)
      ),
      withLatestFrom(this.store.select(selectActivitySideBar)),
      switchMap(([jobs, isSidebarOpen]) => {
        const unread: Activity[] = !isSidebarOpen ? this.helpersService.getFromLocalStorage(`unread-${jobs.results[0]?.scenario?.orgId}`)?.unread : null;
        const internal = this.helpersService.getFromLocalStorage(INTERNAL_USER_LOCAL_STORAGE_KEY).isInternal;
        const scenarioJobs = internal ? jobs.results : jobs.results.filter(job => job.scenario.internal === false);
        return [
          fromActivities.upsertActivities({
            activities: scenarioJobs.map((scenarioJob) => ({
              id: scenarioJob.id,
              type: ActivityType.scenarioJobUpdated,
              message: scenarioJob.state,
              eventName: ActivityType.scenarioJobUpdated,
              read: true,
              actionedBy: scenarioJob.actionedBy,
              createdAt: scenarioJob.createdAt,
              updatedBy: scenarioJob.updatedBy,
              completedAt: scenarioJob.completedAt,
              errors: scenarioJob.errors,
              data: {
                ...scenarioJob.scenario,
                currentScenarioJob: scenarioJob
              }
            }))
          }),
          fromActivities.upsertActivities({
            activities: Boolean(unread) ? unread?.map(activity => ({...activity, read: false})) : []
          })
        ]
      })
    )
  );

  ngrxOnInitEffects(): Action {
    return fromActivities.fetchActivities();
  }

}
