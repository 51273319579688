<div
  class="h-10 inline-flex rounded-lg bg-white dark:bg-background border border-gray-300 dark:border-slate-700/70 dark:text-white overflow-hidden divide-x dark:divide-slate-950 font-medium dark:bg-background">
  @if (showCollapsedMenu$ | async) {
    <app-dropdown-menu [hasDropDown]="true" menuPosition="bottom-left">
      <button buttonContent
        class="text-black/60 dark:text-white/60 active flex-1 px-3.5 h-full flex items-center justify-center text-sm gap-3 hover:bg-soft-blue dark:hover:bg-slate-700">
        <span>
            {{ "global.granularity." + selectedPreset.label| translate }}
          </span>
        <app-svg-images svgName="chevron-down" class="h-3 w-3 block" />
      </button>
      <div menuItems class="text-sm -m-1.5">
        <div class="flex flex-col">
          @for (granularity of nonActiveGranularityOptions; track granularity.label) {
            <button
              (click)="onChange.emit(granularity)"
              class="rounded-lg text-black/60 active flex-1 p-2.5 h-full flex items-center justify-center text-sm gap-3 hover:bg-soft-blue">
          <span>
            {{ "global.granularity." + granularity.label| translate }}
          </span>
            </button>
          }
        </div>
      </div>
    </app-dropdown-menu>
  } @else {
    @for (granularity of granularityOptions; track granularity.label) {
      <button
        (click)="onChange.emit(granularity)"
        [ngClass]="{
          'bg-soft-blue dark:shadow-none dark:bg-black/30 shadow-inner': isActive(granularity),
          'text-black/60 dark:text-white/60': !isActive(granularity),
        }"
        class="active flex-1 px-3.5 h-full flex items-center justify-center text-sm gap-3 hover:bg-soft-blue dark:hover:bg-slate-700">
          <span>
            {{ "global.granularity." + granularity.label| translate }}
          </span>
      </button>
    }
  }
</div>
