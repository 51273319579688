import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { AuthHelpers } from '../auth/auth-helpers';
import { catchError, map } from 'rxjs/operators';
import { ThemeService } from './theme.service';
import { GET_DASHBOARDS } from '../looker/queries';
import { ConfigAwareService } from "../config/models/config";
import { Apollo, gql } from "apollo-angular";
import { SHOW_INTERNAL_FILTER } from '../app.constants';

class LookerOptions {
  public dashboard;
  public granularity?;
}

@Injectable({
  providedIn: 'root'
})
export class LookerService extends ConfigAwareService {

  constructor(
    private http: HttpClient,
    private themeService: ThemeService,
    private apollo: Apollo
  ) {
    super();
  }

  get theme(): string {
    return this.themeService.getCurrentThemeValue().lookerTheme;
  }


  createProductDashboardEmbeddedUrl(productId: number, dashboard, filters = {}, model?: string, hidden_filters = null): Observable<LookerResponse> {
    const params = new URLSearchParams({
      embed_domain: this.environment.embedDomain,
      theme: this.theme,
      hide_filter: SHOW_INTERNAL_FILTER
    });
    hidden_filters?.forEach(filter => params.append('hide_filter', filter));
    return this.http.post<LookerResponse>(this.baseUrl + `products/${ productId }`, {
      dashboard: `${ dashboard }?${ params.toString() }`,
      ...filters
    } as LookerOptions, { params: (model && { model }) })
      .pipe(
        catchError(AuthHelpers.unauthorized)
      );
  }


  createScenarioDashboardEmbeddedUrl(scenarioId: number, dashboard, filters = {}, theme = null, hidden_filters = []): Observable<LookerResponse> {
    const params = new URLSearchParams({
      embed_domain: this.environment.embedDomain,
      theme: theme ?? this.theme
    });

    // Remove duplicates
    [...new Set([...hidden_filters, 'Scenario'])].forEach(filter => params.append('hide_filter', filter));

    return this.http.post<LookerResponse>(this.baseUrl + `scenarios/${ scenarioId }`, {
      dashboard: `${ dashboard }?${ params.toString() }`,
      ...filters
    } as LookerOptions).pipe(
      catchError(AuthHelpers.unauthorized)
    );
  }

  createCustomDashboardUrl(dashboard: string, filters = {}, theme = null): Observable<LookerResponse> {
    dashboard = `${ dashboard }?embed_domain=${ this.environment.embedDomain }&theme=${ theme ?? this.theme }`
    return this.apollo.query({
      fetchPolicy: 'network-only',
      query: gql`
        query GetCustomDashboard($params: GenericScalar) {
          url: customDashboard(params: $params)
        }
      `,
      variables: {
        params: { dashboard, ...filters }
      }
    }).pipe(
      catchError(AuthHelpers.unauthorized),
      map((result: any) => result.data)
    );
  }

  getCustomDashboards(page = 1, pageSize = 50) {
    return this.apollo.watchQuery<any>({
      fetchPolicy: 'network-only',
      query: GET_DASHBOARDS,
      variables: {
        page,
        pageSize
      }
    }).valueChanges.pipe(
      map((result: any) => ({
        group: result.data.orgDashboards,
        personal: result.data.myDashboards
      })),
    )
  }
}

export class LookerResponse {
  public url = 'about:blank';
}
