import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { ConfigAwareService } from '../config/models/config';
import { LOGIN_ERROR_CODES } from '../app.constants';
import { Scenario } from "../../views/scenario/model/scenario.model";

@Injectable({
  providedIn: 'root'
})
export class HelpersService extends ConfigAwareService {

  public isSidebarOpen$ = new BehaviorSubject<boolean>(true);

  constructor(public breakpointObserver: BreakpointObserver) {
    super();
  }

  public saveToLocalStorage(key: string, value: any): void {
    localStorage.setItem(key, JSON.stringify(value));
  }

  public getFromLocalStorage(key: string): { [key: string]: any } {
    const data = localStorage.getItem(key);
    return JSON.parse(data) || {};
  }

  public deleteFromLocalStorage(key: string): void {
    localStorage.removeItem(key);
  }

  public clearLocalStorage(): void {
    localStorage.clear();
  }

  public getDomainFromEmail(email: string): string {
    const domain = /(?:[@]).*/.exec(email);
    return domain ? domain[0].replace('@', '') : null;
  }

  public isProd(): boolean {
    return this.environment.ramp.env.includes('pfm-prod');
  }

  public getBreakpoint(list: string[]): Observable<BreakpointState> {
    return this.breakpointObserver.observe(list);
  }

  public getSupportedErrorCode(status: number): string {
    return LOGIN_ERROR_CODES.includes(status) ? `${ status }` : 'default';
  }

  public generateQueryParams(scenario: Scenario) {
    const queryParams = {
      s: [scenario.id],
      a: scenario.id
    }
    return {
      state: btoa(JSON.stringify(queryParams))
    };
  }

  public toggleSidebar(value: boolean) {
    this.isSidebarOpen$.next(value);
  }
}
