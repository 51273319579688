<div class="relative flex">
  <div data-cy="dropdown-menu-button"
    (click)="toggleMenu()"
    cdkOverlayOrigin
    #trigger="cdkOverlayOrigin"
    class="active">
    <ng-content select="[buttonContent]"></ng-content>
  </div>
  <ng-template
    cdkConnectedOverlay
    [cdkConnectedOverlayOrigin]="trigger"
    [cdkConnectedOverlayOpen]="open()"
    [cdkConnectedOverlayPositions]="[
      getMenuPosition()
    ]"
    [cdkConnectedOverlayPush]="true"
    [cdkConnectedOverlayHasBackdrop]="true"
    cdkConnectedOverlayBackdropClass="cdk-overlay-transparent-backdrop"
    (backdropClick)="closeMenu()">
    @if (hasDropDown) {
      <div
        data-cy="dropdown-menu-content"
        (click)="closeMenu()"
        class="bg-white rounded-lg p-4 border border-gray-300 shadow z-20 min-w-full"
        [ngClass]="{
      'bottom-full mb-1 fade-in-up': menuPosition === 'top-right' || menuPosition === 'top-left',
      'top-full mt-1 fade-in-down': menuPosition === 'bottom-right' || menuPosition === 'bottom-left',
      }">
        <ng-content select="[menuItems]"></ng-content>
      </div>
    }
  </ng-template>
</div>
