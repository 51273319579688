import { Component, EventEmitter, Input, Output } from '@angular/core';
import { DropdownMenuComponent } from "../dropdown-menu/dropdown-menu.component";
import { TranslateModule } from "@ngx-translate/core";
import { BadgeComponent } from "./badge.component";
import { ScenarioType } from "../../../views/scenario/model/scenario.model";
import { Helpers } from "../../shared/helpers";
import { SvgImagesComponent } from "../svg-images/svg-images.component";

@Component({
  selector: 'app-dropdown-badge',
  templateUrl: './dropdown-badge.component.html',
  standalone: true,
  imports: [DropdownMenuComponent, TranslateModule, BadgeComponent, SvgImagesComponent]
})
export class DropdownBadgeComponent {
  @Input() type: ScenarioType;
  @Output() typeChange = new EventEmitter<string>();

  getVariant(type: string) {
    return Helpers.getVariantColor(type as ScenarioType);
  }

  setType(type: string) {
    this.typeChange.emit(type);
  }

  get types() {
    return Object
      .values(ScenarioType)
      .filter(type => ![ScenarioType.DEFAULT].includes(type))
      .filter(type => this.type !== type);
  }

  clearType($event: MouseEvent) {
    $event.stopPropagation();
    this.typeChange.emit(null);
  }

  protected readonly ScenarioType = ScenarioType;
}
