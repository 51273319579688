@if (user$ | async; as user) {
  <div class="flex items-center gap-2">
    <app-organisation-menu class="flex-1"
      [userOrganisations]="user.organisations"
      [currentOrganisation]="user.currentOrganisation"
      (switchOrganisation)="goToOrganisation($event, user, user.currentOrganisation?.id)">
    </app-organisation-menu>
    <app-user-menu
      class="relative flex"
      [user]="user"
      [currentOrganisation]="user.currentOrganisation"
      [toggleInternal]="toggleInternal"
      (toggleInternalMode)="toggleInternalMode($event)"
      (logout)="logout()">
    </app-user-menu>
  </div>
}
