export interface Activity {
  id: number;
  eventName?: string;
  message: string;
  type: ActivityType;
  actionedBy?: string;
  updatedBy?: string;
  createdAt?: string;
  completedAt?: string;
  read?: boolean;
  data?: any;
  errors: any;
}

export enum ActivityType {
  updateAvailable = 'updateAvailable',
  scenarioJobUpdated = 'scenarioJobUpdated',
}
