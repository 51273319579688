import moment from 'moment';
import { FormArray, FormControl, FormGroup } from '@angular/forms';
import { ScenarioSegment } from '../../model/scenario-segments.model';
import { Scenario } from "../../model/scenario.model";
import { Helpers } from 'src/app/core/shared/helpers';

export interface UaOverride {
  productBlendedSegmentIds: string[]
  value: number
}

export interface ScenarioMarketingConfigForm {
  mode?: FormControl<string>;
  scenarioId?: FormControl<number>;
  spendLookbackDate?: FormControl<moment.Moment | string>;
  spendLookbackNumberOfDays?: FormControl<number>;
  spendLookbackRelative?: FormControl<boolean>;
  defaultPaidPercentage?: FormControl<number>;
  overrideProductPaidPercentage?: FormControl<boolean>;
  targetPayback?: FormControl<number>;
  roasTargetPercentage?: FormControl<number>;
  paybackMethod?: FormControl<string>;
  blendedSegmentsList?: FormArray<FormGroup<BlendedSegmentFormGroup>>;
  segmentCount?: FormControl<any>
  defaultMarketingFatigue?: FormControl<number>
  defaultCpiSensitivityToSpend?: FormControl<number>
  paidPercentageOverrides?: FormControl<UaOverride[]>
  cpiSensitivityToSpendOverrides?: FormControl<UaOverride[]>
  marketingFatigueOverrides?: FormControl<UaOverride[]>
  buyClv?: FormControl<boolean>
}


export interface LookbackFormGroup {
  date: FormControl<moment.Moment | string>;
  numberOfDays: FormControl<number>;
  relative: FormControl<boolean>;
}

export interface ScenarioOrganicsSegmentFormGroup {
  id: FormControl<string | null>;
  manualOrganicFactor: FormControl<number | null>;
  manualTrueOrganics: FormControl<number | null>;
}

export interface BlendedSegmentFormGroup {
  paidPercentage: FormControl<number>;
  segmentId: FormControl<string>;
}

export class BlendedSegment {
  paidPercentage?: number
  segmentId?: string
  segment?: ScenarioSegment
}

export interface ProductBlendedSegmentNode {
  id: string
  name: string
}

export interface ProductBlendedSegmentFormGroup {
  id: FormControl<string>
  name: FormControl<string>
}

export interface MarketingConfigLookback {
  date: moment.Moment | string;
  numberOfDays: number;
  relative: boolean;
}

export class ScenarioOrganicsSegment {
  id?: string;
  scenarioId?: number;
  productBlendedSegmentId?: string;
  autoOrganicFactor?: number;
  autoTrueOrganics?: number;
  manualOrganicFactor?: number;
  manualTrueOrganics?: number;
  isManual?: boolean;
  manualUpdatedAt?: moment.Moment | string;
  updatedBy?: string;
  warningType?: OrganicsWarningType;
  productBlendedSegment?: ProductBlendedSegmentNode;

  static fromJson(json: ScenarioOrganicsSegment): ScenarioOrganicsSegment {
    return Object.assign(new ScenarioOrganicsSegment(), {
      ...json,
      autoOrganicFactor: Helpers.toFixedPoint(json.autoOrganicFactor, 2),
      manualOrganicFactor: Helpers.toFixedPoint(json.manualOrganicFactor, 2),
      autoTrueOrganics: Helpers.toFixedPoint(json.autoTrueOrganics, 0),
      manualTrueOrganics: Helpers.toFixedPoint(json.manualTrueOrganics, 0),
      isManual: json.manualOrganicFactor !== null || json.manualTrueOrganics !== null,
      manualUpdatedAt: json?.manualUpdatedAt ? moment.utc(json.manualUpdatedAt).toDate() : null
    });
  }

  static mapOrganicsPreviewData(data: OrganicsPreviewResponse): Highcharts.Options {
    if(!data) return null;
    return {
      colorAxis: {
        max: data.allTimeDataPoints?.length - 1,
      },
      series: [
        {
          name: 'scenario.userAcquisition.preview.axis.fit',
          id: 'fit',
          data: data.fitLinePoints.map(value => ([value.x, value.y])),
          type: 'line',
          marker: {
            fillColor: '#333333',
          },
          color: '#333333',
        },
        {
          name: 'scenario.userAcquisition.preview.axis.allTime',
          id: 'allTime',
          data: data.allTimeDataPoints?.map((value, index) => ({
            x: value.x,
            y: value.y,
            colorValue: index
          })),
          type: 'scatter',
          marker: {
            symbol: 'circle',
            radius: 2.5,
            fillColor: '#005930',
          },
          color: '#005930',
          className: 'no-stroke'
        }, {
          name: 'scenario.userAcquisition.preview.axis.lookback',
          id: 'lookback',
          data: data.lookbackDataPoints?.map(value => ([value.x, value.y])),
          type: 'scatter',
          marker: {
            symbol: 'circle',
            radius: 2.5,
            fillColor: '#fe6a35',
          },
          color: '#fe6a35',
          className: 'no-stroke'
        }
      ]
    }
  }

  static mapOrganicsSegmentPreviewData(data: OrganicsPreviewResponse): Highcharts.Options {
    if(!data) return null;
    return {
      colorAxis: {
        max: data.allTimeDataPoints?.length - 1,
      },
      series: Array.from([
        {
          name: 'scenario.userAcquisition.preview.axis.autoFit',
          data: data.autoFitLinePoints?.map(value => ([value.x, value.y])),
          type: 'line',
          marker: {
            fillColor: '#333333',
          },
          color: '#333333',
        },
        {
          name: 'scenario.userAcquisition.preview.axis.manualFit',
          data: data.manualFitLinePoints?.map(value => ([value.x, value.y])),
          type: 'line',
          marker: {
            fillColor: 'rgba(0, 226, 114, 1)',
          },
          color: 'rgba(0, 226, 114, 1)',
        },
        {
          name: 'scenario.userAcquisition.preview.axis.allTime',
          data: data.allTimeDataPoints?.map((value, index) => ({
            x: value.x,
            y: value.y,
            colorValue: index
          })),
          colorKey: 'colorValue',
          type: 'scatter',
          marker: {
            symbol: 'circle',
            radius: 2.5,
            fillColor: '#005930'
          },
          color: '#005930',
          className: 'no-stroke'
        }, {
          name: 'scenario.userAcquisition.preview.axis.lookback',
          data: data.lookbackDataPoints?.map(value => ([value.x, value.y])),
          type: 'scatter',
          marker: {
            symbol: 'circle',
            radius: 2.5,
            fillColor: '#fe6a35',
          },
          color: '#fe6a35',
          className: 'no-stroke'
        }
      ]).filter(Boolean) as Highcharts.Options['series']
    }
  }
}

export class ScenarioMarketingConfig {
  id?: number;
  mode?: string;
  scenarioId?: number;
  scenario?: Scenario;
  spendLookbackDate?: moment.Moment | string;
  spendLookbackNumberOfDays?: number;
  spendLookbackRelative?: boolean;
  organicsLookbackDate?: moment.Moment | string;
  organicsLookbackNumberOfDays?: number;
  organicsLookbackRelative?: boolean;
  calibrateOrganicsLastRunTime?: moment.Moment | string;
  defaultPaidPercentage?: number;
  targetPayback?: number;
  roasTargetPercentage?: number;
  paybackMethod?: string;
  mappedPaybackMethod?: string;
  blendedSegmentsList?: BlendedSegment[];
  blendedSegments?: ScenarioSegment[];
  segmentCount?: any;
  defaultCpiSensitivityToSpend?: number
  defaultMarketingFatigue?: number;
  overrideProductPaidPercentage?: boolean;
  overrideSegmentsForPaid?: ProductBlendedSegmentNode[]
  overrideSegmentsForPvc?: ProductBlendedSegmentNode[]
  marketingFatigueOverrides?: UaOverride[]
  paidPercentageOverrides?: UaOverride[]
  cpiSensitivityToSpendOverrides?: UaOverride[]
  buyClv?: boolean

  static fromJson(json: any): ScenarioMarketingConfig {
    return Object.assign(new ScenarioMarketingConfig(), {
      ...json,
      blendedSegmentsList: json?.blendedSegmentsList?.edges?.map((edge: any) => edge.node) || []
    });
  }

  static isTargetROAS(mode: string): boolean {
    return mode === MarketingMode.TARGET_PAYBACK || mode === MarketingMode.TARGET_DNU_PAYBACK;
  }

  static getPVLookbackDays(spendLookback: MarketingConfigLookback, startDate: moment.Moment | string | Date): number {
    const {relative, date, numberOfDays} = spendLookback;
    const daysFromDate: number = moment(date).diff(moment(startDate), 'days');
    return relative ? numberOfDays : daysFromDate;
  }

  static mapFormValues(form: ScenarioMarketingConfig): ScenarioMarketingConfig {
    return {
      mode: form.mode,
      spendLookbackDate: !!form.spendLookbackDate ? moment(form.spendLookbackDate).format('YYYY-MM-DD') : null,
      spendLookbackNumberOfDays: form.spendLookbackNumberOfDays,
      spendLookbackRelative: form.spendLookbackRelative,
      defaultPaidPercentage: form.defaultPaidPercentage,
      overrideProductPaidPercentage: form.overrideProductPaidPercentage,
      targetPayback: form.targetPayback,
      roasTargetPercentage: form.roasTargetPercentage,
      paybackMethod: form.paybackMethod,
      defaultCpiSensitivityToSpend: form.defaultCpiSensitivityToSpend,
      defaultMarketingFatigue: form.defaultMarketingFatigue,
      paidPercentageOverrides: form.paidPercentageOverrides,
      cpiSensitivityToSpendOverrides: form.cpiSensitivityToSpendOverrides,
      marketingFatigueOverrides: form.marketingFatigueOverrides,
      buyClv: form.buyClv,
      blendedSegmentsList: form.blendedSegmentsList.map((blendedSegment) => ({
        ...blendedSegment,
        paidPercentage: blendedSegment.paidPercentage / 100
      }))
    }
  }
}

export enum OrganicsCalibrationMethod {
  AUTO ='AUTO_ONLY',
  ALL = 'ALL'
}

export enum OrganicsWarningType {
  BIG_CHANGE = "BIG_CHANGE",
  FIT_LOST = "FIT_LOST",
  FIT_FOUND = "FIT_FOUND"
}

export enum PaybackMethods {
  BLENDED = 'BLENDED',
  PAID = 'PAID'
}

export enum MarketingMode {
  TARGET_NEW_USERS = 'TARGET_NEW_USERS',
  TARGET_SPEND = 'TARGET_SPEND',
  TARGET_PAYBACK = 'TARGET_PAYBACK',
  TARGET_DNU_PAYBACK = 'TARGET_DNU_PAYBACK',
}

export class PlotPoint {
  x: number
  y: number
}

interface IActual {
  spend?: number;
  cpi?: number;
  dnu?: number;
  clv?: number;
  payback?: number;
}

interface IModel {
  m?: number
  c?: number
}

export class PvcPreviewResponse {
  cpiBaseline: PlotPoint[]
  cpiFatigue: PlotPoint[]
  dnuBaseline: PlotPoint[]
  dnuFatigue: PlotPoint[]
  cpiActualLastYear: PlotPoint[]
  cpiActualLookback: PlotPoint[]
  dnuActualLastYear: PlotPoint[]
  dnuActualLookback: PlotPoint[]
  actualsAvg?: IActual
  pvModel?: IModel
}

export class OrganicsPreviewResponse {
  allTimeDataPoints: PlotPoint[]
  lookbackDataPoints: PlotPoint[]
  fitLinePoints?: PlotPoint[]
  manualFitLinePoints?: PlotPoint[]
  autoFitLinePoints?: PlotPoint[]
}

export interface UaSegment {
  id: string;
  productBlendedSegment: ProductBlendedSegmentNode;
  isManual: boolean;
  updatedBy: string;
  updatedAt: moment.Moment | string;
  value1: number;
  value2: number;
  warningInfo: {
    state: boolean;
    type: string;
  };
}

export class UaSegmentListItem {
  id: string;
  productBlendedSegment: ProductBlendedSegmentNode;
  isManual: boolean;
  updatedBy: string;
  updatedAt: moment.Moment | string;
  value1: number;
  value2: number;
  warningInfo: {
    state: boolean;
    type: string;
  };

  constructor(data: UaSegment) {
    this.id = data.id;
    this.productBlendedSegment = data.productBlendedSegment;
    this.isManual = data.isManual;
    this.updatedBy = data.updatedBy;
    this.updatedAt = data.updatedAt;
    this.value1 = data.value1;
    this.value2 = data.value2;
    this.warningInfo = data.warningInfo;
  }
}