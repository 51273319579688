import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import * as fromScenario from '../actions/scenario.actions';
import * as fromAuth from '../../views/user-management/redux/actions';
import { catchError, filter, map, mergeMap, switchMap, tap } from 'rxjs/operators';
import { AlertService } from '../../core/services/alert.service';
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';
import { ScenarioService } from '../../views/scenario/services/scenario.service';
import { from, of, throwError } from 'rxjs';
import { Scenario } from 'src/app/views/scenario/model/scenario.model';
import { GraphQLParams, IGraphQLParams } from 'src/app/graphql/graphql.models';
import { RunwayService } from 'src/app/views/scenario/reports/services/runway.service';
import { HelpersService } from 'src/app/core/services/helpers.service';
import { INTERNAL_USER_LOCAL_STORAGE_KEY } from 'src/app/core/app.constants';

@Injectable()
export class ScenarioEffects {

  loadFiles$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromScenario.loadScenarioFiles),
      mergeMap(({scenarioId}) => this.scenarioService.find(scenarioId).pipe(
        map((scenario) => fromScenario.updateScenarioSuccess({scenario: {id: scenarioId, changes: scenario}})),
      ))
    )
  );

  update$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromScenario.updateScenario),
      mergeMap(action => this.scenarioService.updateScenario(action.scenarioUpdate).pipe(
        tap(() => (action.next !== undefined ? action.next() : null)),
        map(({body}) => fromScenario.updateScenarioSuccess({scenario: {id: body.id, changes: body}})),
        catchError((error) => {
          this.messageService.error(this.translateService.instant('error.default'));
          return throwError(() => error);
        })
      )),
    )
  );

  removeScenarioType$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromScenario.resetScenarioType),
      mergeMap(action => this.scenarioService.removeScenarioType(action.scenarioUpdate).pipe(
        map((scenario) => fromScenario.updateScenarioSuccess({scenario: {id: scenario.id, changes: scenario}})),
        catchError((error) => {
          this.messageService.error(this.translateService.instant('error.default'));
          return throwError(() => error);
        })
      )),
    )
  );

  loadScenarioLatestJob$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromScenario.loadScenarioLatestJob),
      mergeMap(action => this.scenarioService.getLatestJob(action.scenarioId)
        .pipe(
          map(scenarioLatestJob => (fromScenario.loadScenarioLatestJobSuccess({scenarioLatestJob}))),
          catchError((error) => of(fromScenario.loadScenarioLatestJobFailure({error})))
        )
      ),
    )
  );

  processed$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromScenario.scenarioProcessed),
      tap(({scenarioId, productId, name, productName}) => {
        this.messageService.messageWithAction(this.translateService.instant('data.scenario.processed', {name, product: productName}), 'success', 'View', () => {
            this.router.navigate(['/products', productId, {
              outlets: {
                aux: null,
                primary: ['reports', 'overview']
              }
            }], {
              skipLocationChange: false,
              queryParams: this.helpersService.generateQueryParams({id: +scenarioId})
            });
        })
      }),
    )
    , {dispatch: false});

  snapshotDataDeleted$ = createEffect(() =>
      this.actions$.pipe(
        ofType(fromScenario.scenarioSnapshotDataDeleted),
        tap(({scenarioId}) => {
          this.messageService.message(this.translateService.instant('data.scenario.snapshotDeleted', {name: scenarioId}), 'success');
        })
      )
    , {dispatch: false});

  setPillarModel$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromScenario.setPillarModel),
      mergeMap(({pillar}) => this.scenarioService.getPillarModel(pillar).pipe(
          map((pillarModels) => fromScenario.setPillarModelSuccess({pillar, pillarModels})),
          catchError((err) => of(fromScenario.setPillarModelFailure(err)))
        )
      ))
  );

  setPillarModels$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromScenario.setPillarModels),
      mergeMap(() => this.scenarioService.getPillarModels().pipe(
          map((pillarModels) => fromScenario.setPillarModelsSuccess({pillarModels})),
          catchError((err) => of(fromScenario.setPillarModelsFailure(err)))
        )
      ))
  );

  toggleInternalScenariosData$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromAuth.toggleInternalData),
      filter(({paginationParams}) => !!paginationParams.filter.productId),
      map(({paginationParams}) => {
        // Refetch scenario breadcrumbs list (all scenarios for the product)
        return fromScenario.fetchScenarios({
          paginationParams: {
            filter: paginationParams.filter
          }
        });
      })
    )
  );

  loadScenarios$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromScenario.fetchScenarios),
      map(({paginationParams}) => {
        const isInternal = this.helpersService.getFromLocalStorage(INTERNAL_USER_LOCAL_STORAGE_KEY)?.isInternal;
        const hasPagination = paginationParams.page || paginationParams.pageSize;
        return !!hasPagination ? paginationParams : {filter: {...paginationParams.filter, ...(!isInternal && {internal: false})}};
      }),
      mergeMap((paginationParams) => this.scenarioService.query<Scenario>(this.getScenariosParams(paginationParams))
        .pipe(
          map(({results, totalCount}) => {
            const hasPagination = paginationParams.page || paginationParams.pageSize;
            if (!!hasPagination) {
              return fromScenario.loadScenarios({scenarios: results, totalCount});
            }
            return fromScenario.loadBreadcrumbScenarios({scenarios: results, totalCount});
          }),
          catchError((error) => {
            this.messageService.error(this.translateService.instant('error.default'));
            return throwError(() => error);
          })
        )
      ),
    )
  );

  updateScenario$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromScenario.scenarioUpdated),
      mergeMap(({scenarioId}) => this.scenarioService.find(scenarioId).pipe(
        map((scenario) => fromScenario.updateScenarioSuccess({scenario: {id: scenarioId, changes: scenario}})),
        catchError((error) => {
          this.messageService.error(this.translateService.instant('error.default'));
          return throwError(() => error);
        })
      ))
    )
  );

  updateScenarioFavourite$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromScenario.updateScenarioFavourite),
      mergeMap(({scenario: {changes: { id, favourite}}}) => this.scenarioService.setScenarioFavourite(id, favourite).pipe(
        map((scenario) => fromScenario.updateScenarioSuccess({scenario: {id, changes: scenario}})),
        catchError((error) => {
          this.messageService.error(this.translateService.instant('error.default'));
          return throwError(() => error);
        })
      ))
    )
  );

  updateScenarioSettings$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromScenario.updateScenarioSettings),
      mergeMap(({scenarioUpdate, trackingData}) => from(this.scenarioService.update(scenarioUpdate, trackingData)).pipe(
        map((scenario) => fromScenario.updateScenarioSuccess({scenario: {id: scenario.id, changes: scenario}})),
        catchError((error) => {
          this.messageService.error(this.translateService.instant('error.default'));
          return throwError(() => error);
        })
      ))
    )
  );

  archiveScenario$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromScenario.archiveScenario),
      mergeMap(({scenario, paginationParams}) => this.scenarioService.archive(scenario).pipe(
        map((scenario) => fromScenario.updateScenarioSuccess({scenario: {id: scenario.id, changes: scenario}})),
        catchError((error) => {
          this.messageService.error(this.translateService.instant('error.default'));
          return throwError(() => error);
        }),
        switchMap(() => [
          // Refetch scenario breadcrumbs list (all scenarios for the product)
          fromScenario.fetchScenarios({paginationParams: {filter: paginationParams.filter}}),
          // Update scenario list in state
          fromScenario.fetchScenarios({paginationParams})
        ])
      )),
    )
  );

  runScenario$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromScenario.runScenario),
      mergeMap(({scenario}) => this.runwayService.processScenario(scenario).pipe(
        map(() => {
          this.messageService.message(this.translateService.instant('scenario.config.advanced.runMessage', {name: scenario.name}), 'success');
          return fromScenario.loadScenarioLatestJob({scenarioId: scenario.id});
        }),
        catchError((err) => {
          this.messageService.add({
            severity: 'error',
            summary: this.translateService.instant('error.error'),
            detail: `${err.error?.status}: ${err.error?.message}`,
            life: 8000
          });
          return throwError(() => err);
        }),
      )),
    )
  );

  cancelScenario$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromScenario.cancelScenario),
      mergeMap(({id}) => this.scenarioService.cancelScenario(id).pipe(
        map(({name}) => fromScenario.displayMessage({
          detail: 'scenario.config.advanced.cancelRunMessage',
          summary: 'scenario.config.advanced.cancelTitle',
          params: {name}
        })),
        catchError((err) => {
          this.messageService.add({
            severity: 'error',
            summary: this.translateService.instant('error.error'),
            detail: `${err.error?.status}: ${err.error?.message}`,
            life: 8000
          });
          return throwError(() => err);
        }),
      )),
    )
  );

  deleteScenario$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromScenario.deleteScenario),
      mergeMap(({scenario, shouldRedirect}) => this.scenarioService.delete(scenario).pipe(
        map(() => {
          if (shouldRedirect) {
            this.router.navigate(['/products', scenario.product.id, 'scenarios'])
          }
          return fromScenario.deleteScenarioById({id: scenario.id,});
        }),
        catchError((err) => {
          this.messageService.add({
            severity: 'error',
            summary: this.translateService.instant('error.error'),
            detail: `${err.error?.status}: ${err.error?.message}`,
            life: 8000
          });
          return throwError(() => err);
        }),
      )),
    )
  );

  displayMessage$ = createEffect(() =>
      this.actions$.pipe(
        ofType(fromScenario.displayMessage),
        tap(({detail, summary, severity, params}) => {
          const details = this.translateService.instant(detail, (!!params && params));
          switch (severity) {
            case 'error':
              this.messageService.error(details);
              break;
            case 'success':
              this.messageService.success(
                details,
                this.translateService.instant(summary),
              );
              break;
            default:
              this.messageService.add({
                severity: severity || 'info',
                summary: this.translateService.instant(summary),
                detail: details,
                life: 8000
              });
              break;
          }
        }))
    , {dispatch: false});


  updateScenarioConfig$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromScenario.updateScenarioConfig),
      mergeMap(({scenario}) => this.scenarioService.updateConfig(scenario).pipe(
        map(({body}) => fromScenario.updateScenarioSuccess({scenario: {id: body.id, changes: body}})),
        catchError((error) => {
          this.messageService.error(this.translateService.instant('error.default'));
          return throwError(() => error);
        })
      ))
    )
  );

  updateScenarioLoggedEvent$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromScenario.scenarioUserEventLogged),
      map(({scenarioUserEvent}) => fromScenario.updateScenarioSuccess({
        scenario: {
          id: scenarioUserEvent.scenarioId,
          changes: {
            scenarioUserEvent: scenarioUserEvent.scenario
          }
        }
      })),
      catchError((error) => {
        this.messageService.error(this.translateService.instant('error.default'));
        return throwError(() => error);
      })
    )
  );

  private getScenariosParams(paginationParams: IGraphQLParams): GraphQLParams {
    const {page, pageSize, sort, filter} = paginationParams;
    const {productId, searchText, internal} = filter;
    const archived = this.helpersService.getFromLocalStorage('showArchivedScenarios')?.archived;

    return {
      filter: {
        ...(!archived && {archived: false}),
        ...(typeof internal === 'boolean' && {internal}),
        productId,
        ...(searchText && {nameIlike: `%${searchText}%`})
      },
      sort: (sort && sort.length > 0 ? sort : ['name', 'asc']),
      ...(pageSize && {pageSize: +pageSize}),
      ...(page && {page: +page})
    }
  }

  constructor(private actions$: Actions,
              public messageService: AlertService,
              private router: Router,
              public scenarioService: ScenarioService,
              private runwayService: RunwayService,
              private helpersService: HelpersService,
              private translateService: TranslateService) {
  }
}
