<button [cdkMenuTriggerFor]="menu" type="button" aria-expanded="false" class="active active p-2 hover:rotate-6">
  @if (user?.picture.length > 1) {
    <div style="background-image: url('{{ user.picture }}');"
      class="rounded-full bg-contain h-5 w-5"></div>
  } @else {
    <div
      class="h-5 w-5 bg-kohort-tertiary hover:bg-white/50 text-white/80 hover:text-black rounded-full border border-white/10 flex items-center justify-center">
      <app-svg-images svgName="user"></app-svg-images>
    </div>
  }
</button>
<ng-template #menu>
  <div class="bg-white rounded-xl border border-gray-300 shadow-md py-1.5 px-3 text-black fade-in space-y-1 text-sm"
    cdkMenu>
    <div class="flex gap-3 items-center py-3">
      @if (user?.picture.length > 1) {
        <div style="background-image: url('{{ user.picture }}');" class="h-8 w-8 rounded-full bg-contain"></div>
      } @else {
        <div
          class="bg-kohort-tertiary text-white/80 h-8 w-8 rounded-full border border-white/10 flex items-center justify-center">
          <app-svg-images svgName="user"></app-svg-images>
        </div>
      }
      <div class="space-y-1">
        <h3 class="font-medium leading-none">{{ user.name }}</h3>
        <p class="text-gray-500 leading-none">{{ user.email }} </p>
      </div>
    </div>
    @if (user.isInternal) {
      <div class="py-1.5 px-3 flex items-center text-sm gap-3">
        <app-svg-images class="h-4.5 opacity-50" svgName="eye"></app-svg-images>
        <span>{{ 'global.showInternal' | translate }}</span>
        <app-switch-toggle data-cy="internal-checkbox" identifier='internalLabel' [formControl]="toggleInternalControl"
        ></app-switch-toggle>
      </div>
    }
    <div class="divide-y divide-gray-200/70 border-gray-200/70 border-t">
      <div class="space-y-1 py-1" *HasAuthority="['ROLE_SUPER_ADMIN', 'ROLE_ADMIN']">
        <!-- Go to Organisation -->
        <button *HasAuthority="'ROLE_SUPER_ADMIN'"
          [routerLink]="[userManagementRoute, currentOrganisation?.id, 'organisations']"
          class="flex items-center gap-3 px-3 py-2.5 w-full hover:bg-soft-blue active rounded-md hover:text-digital-blue"
          cdkMenuItem>
          <app-svg-images class="h-4.5 opacity-50" svgName="building-office-2"></app-svg-images>
          <span translate="userManagement.users.buttons.organisations"></span>
        </button>
        <!-- Go to company -->
        <button *HasAuthority="'ROLE_ADMIN'" [routerLink]="[userManagementRoute, currentOrganisation?.id]"
          class="flex items-center gap-3 px-3 py-2.5 w-full hover:bg-soft-blue active rounded-md hover:text-digital-blue"
          cdkMenuItem>
          @if (currentOrganisation?.logo) {
            <div style="background-image: url('{{ currentOrganisation?.logo }}');"
              class="h-4 w-4 rounded bg-contain bg-soft-blue"></div>
          } @else {
            <app-svg-images class="h-4.5 opacity-50" svgName="cog-6-tooth"></app-svg-images>
          }
          <span translate="userManagement.users.buttons.company"></span>
        </button>
      </div>

      <div class="space-y-1 py-1">
        <!-- Go to profile -->
        <button [routerLink]="[userManagementRoute, user.currentOrgId, 'profile']"
          class="flex items-center gap-3 px-3 py-2.5 w-full hover:bg-soft-blue active rounded-md hover:text-digital-blue"
          cdkMenuItem>
          <app-svg-images class="h-4.5 opacity-50" svgName="user-circle"></app-svg-images>
          <span translate="userManagement.users.buttons.profile"></span>
        </button>
      </div>
      <div class="pt-2 space-y-1">
        <button (click)="logout.emit()"
          class="flex items-center gap-3 p-2 w-full hover:bg-soft-blue active rounded-md hover:text-digital-blue"
          cdkMenuItem>
          <app-svg-images class="h-4.5 opacity-50" svgName="arrow-left-end-on-rectangle"></app-svg-images>
          <span translate="userManagement.users.buttons.logout"></span>
        </button>
      </div>
      <div class="text-center text-gray-500/70 text-[10px] pt-1">
        {{ version }} ({{ currentOrganisation.id }})
      </div>
    </div>
  </div>
</ng-template>
