import { ChangeDetectionStrategy, Component, EventEmitter, inject, Input, Output } from '@angular/core';
import { BaseGranularity } from "../../looker/base-granularity/base-granularity";
import { TranslateModule } from "@ngx-translate/core";
import { AsyncPipe, NgClass } from "@angular/common";
import { Preset } from "../../looker/looker-granularity/looker-granularity.component";
import { Breakpoints } from "@angular/cdk/layout";
import { DropdownMenuComponent } from "../dropdown-menu/dropdown-menu.component";
import { mergeMap } from "rxjs";
import { map } from "rxjs/operators";
import { SvgImagesComponent } from "../svg-images/svg-images.component";
import { HelpersService } from "../../services/helpers.service";

@Component({
  selector: 'app-granularity-dropdown',
  standalone: true,
  imports: [
    TranslateModule,
    NgClass,
    AsyncPipe,
    DropdownMenuComponent,
    SvgImagesComponent
  ],
  templateUrl: './granularity-dropdown.component.html',
  styleUrl: './granularity-dropdown.component.scss',
  host: {
    class: 'z-100 absolute top-0 mt-2 transition-all block overflow-hidden'
  },
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class GranularityDropdownComponent extends BaseGranularity {

  private helpersService = inject(HelpersService);

  @Input() isLoaded: boolean = false;
  @Input() selectedPreset: Preset;

  @Output() onChange = new EventEmitter<Preset>();

  showCollapsedMenu$ = this.helpersService.getBreakpoint([Breakpoints.Large]).pipe(
    mergeMap((value) => this.helpersService.isSidebarOpen$.pipe(
      map((sidebarOpen) => sidebarOpen && value.matches)
    )),
  )

  isActive(granularity: Preset) {
    return granularity.label === this.selectedPreset?.label;
  }
}
