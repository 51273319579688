import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { SvgImagesComponent } from '../svg-images/svg-images.component';
import { TranslateModule } from '@ngx-translate/core';
import { TooltipDirective } from '../../directives/tooltip.directive';

@Component({
  selector: 'app-segment-summary',
  templateUrl: './segment-summary.component.html',
  styles: [`
    :host {
      display: inline-block;
    }
  `],
  standalone: true,
  imports: [CommonModule, SvgImagesComponent, TranslateModule, TooltipDirective]
})
export class SegmentSummaryComponent {
  @Input() segments: string[] = [];
  @Input() alwaysExpanded: boolean = true;
  @Input() segmentCount: number;

  public totalVisibleSegments = 5;
}
