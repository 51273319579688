import { DOCUMENT } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AppState, AuthService, User } from '@auth0/auth0-angular';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { Observable, filter, firstValueFrom, lastValueFrom, map, switchMap, tap } from 'rxjs';
import {
  getAuthUser,
  organisationChanged,
  clearProducts,
  loadApp,
  toggleInternalData,
  selectScenario,
} from 'src/app/store';
import { IOrganisation, IUser, UserRole } from 'src/app/views/user-management/model/user-management.model';
import {
  FIRST_TIME_LOGIN_COUNT,
  USER_MANAGEMENT_ROUTE,
  HOME_ROUTE,
  USER_ROLE_LOCAL_STORAGE_KEY,
  INTERNAL_USER_LOCAL_STORAGE_KEY
} from '../../app.constants';
import { Auth0UserTokenKeys } from '../../config/models/config';
import { AccountService } from '../../services/account.service';
import { AlertService } from '../../services/alert.service';
import { HelpersService } from '../../services/helpers.service';
import { LoadingService } from '../../services/loading.service';
import { IUserState } from 'src/app/views/user-management/redux/reducers';

@Component({
  selector: 'app-user-orgs-menu',
  templateUrl: './user-orgs-menu.component.html',
  styleUrls: ['./user-orgs-menu.component.scss']
})
export class UserOrgsMenuComponent implements OnInit {

  public user$: Observable<IUserState>;
  public toggleInternal: boolean;
  private isNormalUser: boolean;

  constructor(
    private store: Store<AppState>,
    public auth0Service: AuthService,
    private loadingService: LoadingService,
    public router: Router,
    private activatedRoute: ActivatedRoute,
    private translateService: TranslateService,
    @Inject(DOCUMENT) public document: Document,
    private messageService: AlertService,
    private helpersService: HelpersService,
    private accountService: AccountService
  ) {
  }

  ngOnInit(): void {
    this.user$ = this.accountService.onTokenChanged$.pipe(
      switchMap((token) => this.store.select(getAuthUser).pipe(
        filter(user => !!user),
        map((user) => {
          this.toggleInternal = this.accountService.initInternalToggleValue(user.isInternal);
          return {
            ...user,
            organisations: user.organisations?.results,
            currentOrganisation: this.getCurrentUserOrganisation(token?.org_id, user.organisations?.results)
          } as IUserState;
        }),
        tap(({ loginCount, currentOrganisation, name, email }) => {
          this.checkUserProfile(loginCount, currentOrganisation, name, email);
        }),
      )));
  }

  private getCurrentUserOrganisation(orgId: string, userOrganisations: IOrganisation[]): IOrganisation {
    return userOrganisations.find(org => org.id === orgId);
  }

  goToOrganisation(organisation: IOrganisation, user: IUser, currentOrgId: string): void {
    if (organisation.id === currentOrgId) return;
    this.messageService.add({
      severity: 'info',
      summary: '',
      detail: this.translateService.instant('userManagement.notifications.switching'),
      life: 8000
    });

    // clear queryparams
    this.router.navigate([], { queryParams: null });
    lastValueFrom(this.auth0Service.getAccessTokenSilently({
      authorizationParams: { organization: organisation.id },
      cacheMode: 'off'
    })).then(_ => {

      const identity: User = {
        sub: user.id,
        name: user.name,
        email: user.email,
        org_id: organisation.id,
        org_name: organisation.name,
        created_at: user.createdAt
      };

      this.accountService.authenticate(identity);
      // clear the internal user flag when switching orgs
      this.helpersService.deleteFromLocalStorage(INTERNAL_USER_LOCAL_STORAGE_KEY);

      this.store.dispatch(organisationChanged({organisation: organisation}));
      this.store.dispatch(clearProducts())
      this.messageService.dismissMessage();

      setTimeout(() => {
        if (![USER_MANAGEMENT_ROUTE, HOME_ROUTE].includes(this.router.url) || this.isNormalUser) {
          this.router.navigate([HOME_ROUTE]);
        }
      }, 250);

      return firstValueFrom(this.auth0Service.idTokenClaims$);
    }).then((token) => {
      const roles = token[Auth0UserTokenKeys.ROLES];
      const isSuperAdmin = roles.includes(UserRole.SUPER_ADMIN);
      const isNormalUser = !(roles.includes(UserRole.ADMIN) || roles.includes(UserRole.SUPER_ADMIN));
      this.store.dispatch(loadApp({ userId: user.id, orgId: organisation.id, isNormalUser, isSuperAdmin }));
      this.accountService.setInternalRole(this.toggleInternal);
    }).catch(e => {
      this.messageService.error(
        this.translateService.instant('global.session.expired')
      )
      this.logout();
    });
  }

  async toggleInternalMode(isInternal: boolean): Promise<void> {
    const { queryParams, data } = this.activatedRoute.snapshot;
    const isUserManagementPage = this.router.url?.includes(USER_MANAGEMENT_ROUTE);
    const scenario = await firstValueFrom(this.store.select(selectScenario).pipe(map(scenario => scenario?.id ? scenario : null)));
    this.helpersService.saveToLocalStorage(INTERNAL_USER_LOCAL_STORAGE_KEY, { isInternal });
    this.accountService.setInternalRole(isInternal);

    if (!isUserManagementPage) {

      if (!isInternal && scenario?.internal && !data?.product?.internal) {
        this.router.navigate([HOME_ROUTE, scenario.product.id, 'scenarios']);
      }

      if (!isInternal && data?.product?.internal) {
        this.router.navigate([HOME_ROUTE]);
      }

      this.store.dispatch(toggleInternalData({
        paginationParams: {
          ...queryParams,
          filter: {
            ...(!!data?.product && { productId: data?.product?.id }),
          }
        }
      }));
    }
  }

  async logout(): Promise<void> {
    // this helps the perception that nothing is happening when you click logout
    this.loadingService.show();
    this.accountService.resetEventTrackers();
    this.helpersService.deleteFromLocalStorage(USER_ROLE_LOCAL_STORAGE_KEY);
    await firstValueFrom(this.auth0Service.logout({ logoutParams: { returnTo: this.document.location.origin } }));
  }

  checkUserProfile(loginCount: number, currentOrganisation: IOrganisation, name: string, email: string): void {
    if (loginCount === FIRST_TIME_LOGIN_COUNT && currentOrganisation?.id && name == email) {
      this.router.navigate([USER_MANAGEMENT_ROUTE, currentOrganisation.id, 'profile']);
    }
  }
}
