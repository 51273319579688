<div class="relative h-full w-full">
  @if ((segments$ | async)?.length > 50 && isBySegmentsChart) {
    <div class="pt-2 absolute top-1 left-1">
      <mat-icon aria-hidden="false" aria-label="icon" class="text-amber-400"
        [appTooltip]="'report.banner.warn.segmentsLimit' | translate">warning
      </mat-icon>
    </div>
  }
  <iframe height="100%" id="looksie" #lookerIframe
    class="border-none bg-soft-blue dark:bg-slate-950"
    width="100%">
  </iframe>
</div>
<div
  class="absolute top-0 bottom-0 left-0 right-0 inset-0 z-10 flex flex-col items-center justify-center text-gray-300 dark:text-slate-800 transition-all dark:bg-background bg-white"
  [ngClass]="{
    'opacity-0 pointer-events-none': !isLoading
  }">
  <div class="flex items-center justify-center p-20 relative">
    <div class="absolute inset-0 flex items-center justify-center">
      <app-svg-images svgName="spinner" class="h-24 w-24"></app-svg-images>
    </div>
    <div>
      <img src="/assets/images/logo-small-dark.png" class="h-5 dark:hidden">
      <img src="/assets/images/logo-small.png" class="h-5 hidden dark:block">
    </div>
  </div>
</div>
